import Block from "../Block";
import {Box, Button, Grid, Slider, TextField, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {colors} from "../../theme";
import {cdn, formatPrice} from "../../util";
import {ChangeEvent, IStatePlantATree} from "../../types";
import {PaymentGrayBox} from "../../Pages/Payment";
import {useAppDispatch, useAppSelector} from "../../store";
import {getLocalPlantATreeAmount, updateLocalPlantATreeAmount} from "../../features/plantATree/thunks/plantATreeAmount";

const treeQuantities: Array<number> = [1, 4, 8, 16, 20];

const inputToSlider = (inputValue: number): number => {
    inputValue = inputValue < 5 ? 5 : inputValue;
    let sliderValue = 0;
    if (inputValue > 5 && inputValue <= 20) {
        sliderValue = (inputValue - 5) / 15;
    } else if (inputValue > 20 && inputValue <= 40) {
        sliderValue = inputValue / 20;
    } else if (inputValue > 40 && inputValue <= 80) {
        sliderValue = inputValue / 40 + 1;
    } else if (inputValue > 80 && inputValue <= 100) {
        sliderValue = inputValue / 20 - 1;
    } else if (inputValue > 100) {
        sliderValue = 4;
    }

    return sliderValue;
}

const PlantATree: React.FC = () => {
    const dispatch = useAppDispatch();
    const plantATree: IStatePlantATree = useAppSelector(state => state.plantATree);
    const offerId: string = useAppSelector(state => state.flightOffer.offerId);
    const priceCurrency: string = useAppSelector(state => state.flightOffer.total.priceDetails.currency);
    const plantedTrees: Array<any> = [
        {text: "That’s how many trees we’ve planted to date", quantity: "1,117,899"},
        {text: "With over 100,000 taking root this year", quantity: "108,899"}
    ];

    const [sliderValue, setSliderValue] = useState<number>(inputToSlider(plantATree.amount));
    const [textValue, setTextValue] = useState<number>(plantATree.amount);
    const shouldButtonBeDisabled = useCallback(
        () => plantATree.amount === textValue && plantATree.confirmed,
        [plantATree, textValue, offerId]
    );
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(shouldButtonBeDisabled());

    const marks = treeQuantities.map((item, index) => {
        return {value: index, label: formatPrice(item * 5, priceCurrency, 0)}
    });

    useEffect(() => {
        if (offerId) {
            dispatch(getLocalPlantATreeAmount());
        }
    }, [dispatch, offerId]);

    useEffect(() => {
        setConfirmButtonDisabled(shouldButtonBeDisabled());
    }, [plantATree, offerId, textValue]);

    useEffect(() => {
        setTextValue(plantATree.amount);
        setSliderValue(inputToSlider(plantATree.amount));
    }, [plantATree.amount]);

    const handleChangeSlider = (e: Event, value: number | number[]) => {
        setSliderValue(value as number);
        setTextValue(treeQuantities[value as number] * 5)
    }

    const handleChangeInput = (e: ChangeEvent) => {
        let inputValue = parseInt(e.target.value || plantATree.amount.toString());
        setSliderValue(inputToSlider(inputValue));
        setTextValue(inputValue);
    };

    const handleConfirm = () => {
        dispatch(updateLocalPlantATreeAmount(textValue));
        setConfirmButtonDisabled(true);
    }

    return (
        <Block title={"Did you know?"} topRounded bottomRounded>
            <Typography
                component={"h2"}
                sx={{
                    padding: {xs: 1, md: 3},
                    fontSize: "2.8rem",
                    fontWeight: 700
                }}
            >
                Priority Flyers will plant a tree for every ticket sold.<br/>
                Join us in this effort!
            </Typography>

            <Grid container sx={{paddingX: {xs: 1, md: 3}}}>
                {plantedTrees.map((item, index) =>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        key={index}
                        sx={{
                            borderLeft: index > 0 ? {xs: "none", md: "1px dashed #9BA3BE"} : "none"
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "3.8rem",
                                color: colors.red,
                                textAlign: "center",
                                fontWeight: 600
                            }}
                        >
                            {item.quantity}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "2.1rem",
                                color: colors.secondDarkBackground,
                                textAlign: "center",
                                fontWeight: 500
                            }}
                        >
                            {item.text}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Box sx={{paddingX: {xs: 1, md: 3}, paddingY: 7}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {treeQuantities.map((item, index) =>
                        <Grid
                            item
                            key={index}
                            sx={{
                                display: "flex",
                                position: "relative",
                                alignItems: "end"
                            }}
                        >
                            {index > 0 &&
                                <Typography
                                    component={"span"}
                                    sx={{
                                        fontSize: {xs: "2.3rem", md: "2.8rem"},
                                        fontWeight: 500,
                                        position: "absolute",
                                        right: {xs: 28, md: 61},
                                        top: {xs: 8, md: 0}
                                    }}
                                >
                                    {item}x
                                </Typography>
                            }
                            <Box
                                component={"img"}
                                src={cdn('/images/tree.svg')}
                                sx={{width: {xs: "30px", md: "auto"}}}
                            />
                        </Grid>
                    )}
                </Grid>

                <Box
                    sx={{
                        paddingLeft: {xs: 1.75, md: 3.5},
                        paddingRight: {xs: 2, md: 4},
                        marginTop: {xs: 1.25, md: 2.5}
                    }}
                >
                    <Slider
                        onChange={handleChangeSlider}
                        value={sliderValue}
                        marks={marks}
                        min={0}
                        max={4}
                        //scale={calculateScale}
                        step={1}
                        size={"medium"}
                        sx={{
                            color: colors.gray,
                            '& .MuiSlider-thumb': {
                                height: 5 * sliderValue + 24,
                                width: 5 * sliderValue + 24,
                                backgroundColor: colors.red
                            },
                            '& .MuiSlider-markLabel': {
                                fontSize: "2.8rem",
                                fontWeight: 500
                            }
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    paddingX: {xs: 1, md: 3},
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: {xs: "flex-start", md: "center"},
                    flexDirection: {xs: "column", sm: "row"}
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontSize: "2.8rem",
                        fontWeight: 700
                    }}
                >
                    Do you want to plant more?
                </Typography>
                <TextField
                    value={textValue}
                    onChange={handleChangeInput}
                    sx={{maxWidth: 234}}
                    InputProps={{sx: {fontSize: "2.8rem", fontWeight: 500}}}
                    inputProps={{
                        min: 0,
                        type: 'number'
                    }}
                />
            </Box>

            <PaymentGrayBox
                sx={{
                    borderRadius: "9px",
                    marginTop: {xs: 3, md: 6},
                    paddingY: {xs: 2, md: 4},
                    columnGap: 3,
                    rowGap: 3,
                    flexDirection: {xs: "column", sm: "row"}
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 700
                    }}
                >
                    By clicking button "Confirm" I agree, that this amount will be charged in addition to the cost of
                    the airline ticket(s).
                </Typography>
                <Button
                    onClick={handleConfirm}
                    disabled={confirmButtonDisabled}
                >
                    Confirm
                </Button>
            </PaymentGrayBox>

            <Box sx={{height: 8}}></Box>
        </Block>
    );
}

export default PlantATree;