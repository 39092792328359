import React, {useContext} from "react";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material";
import {Gender, IError} from "../../../types";
import AlertText from "../../AlertText";
import {colors} from "../../../theme";
import PassengerDateOfBirth from "./PassengerDateOfBirth";
import {PassengerFunctionsContext, PassengerProps} from "./index";
import {useAppSelector} from "../../../store";
import useErrors from "../../../hooks/useErrors";

const BookingPassenger: React.FC<PassengerProps> = props => {
    const {passenger, passengerType} = props;
    const passengersErrors: Array<Array<IError>> = useAppSelector(state => state.passengers.errors);
    const errors = passengersErrors[passenger.id];
    const {getFieldError} = useErrors(errors);

    const {handleInputChange} = useContext(PassengerFunctionsContext);

    return (
        <>
            <AlertText
                text="The passenger names must match the travel documents. Name changes are not allowed."
                sx={{
                    marginBottom: 2,
                    backgroundColor: colors.liteGrayBackground,
                    paddingX: {xs: 3, md: 1, lg: 3},
                    paddingY: 2
                }}
            />
            <Grid
                container
                rowSpacing={1}
                id={`passenger-${passenger.id}`}
            >
                <Grid item>
                    <FormControl>
                        <FormLabel required>Gender</FormLabel>
                        <RadioGroup row value={passenger.gender} name="gender" onChange={handleInputChange}>
                            <FormControlLabel value={Gender.MALE} control={<Radio/>} label="Male"/>
                            <FormControlLabel value={Gender.FEMALE} control={<Radio/>} label="Female"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    name="firstName"
                                    value={passenger.firstName}
                                    onChange={handleInputChange}
                                    error={!!getFieldError("firstName")}
                                    helperText={getFieldError("firstName")}
                                    label={"First name"}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    name="lastName"
                                    value={passenger.lastName}
                                    onChange={handleInputChange}
                                    error={!!getFieldError("lastName")}
                                    helperText={getFieldError("lastName")}
                                    label={"Last name"}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    name="middleName"
                                    value={passenger.middleName}
                                    onChange={handleInputChange}
                                    label={"Middle name"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <PassengerDateOfBirth passenger={passenger} passengerType={passengerType}/>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default BookingPassenger;