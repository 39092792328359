import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStatePurchaseSummary} from "../../types";
import {purchaseSummarySliceDefaultValue} from "../../defaultValues";

const purchaseSummaryAdapter = createEntityAdapter();

const initialState: IStatePurchaseSummary = purchaseSummaryAdapter.getInitialState(purchaseSummarySliceDefaultValue);

const purchaseSummarySlice = createSlice({
    name: "purchaseSummary",
    initialState,
    reducers: {
        openPurchaseSummary: (state: IStatePurchaseSummary, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        }
    }
});

export const {openPurchaseSummary} = purchaseSummarySlice.actions;
export default purchaseSummarySlice.reducer;