import React, {useEffect} from "react";
import Header from "../Header";
import Footer from "../Footer";
import {useLocation, useParams} from "react-router-dom";
import {useAppDispatch} from "../../store";
import {saveOfferId} from "../../features/flight-offer/flightOfferSlice";

type Props = {
    children: React.ReactNode,
};

const Page: React.FC<Props> = props => {
    const params = useParams();
    const offerId = params.offerId;
    const dispatch = useAppDispatch();
    const {children} = props;
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        dispatch(saveOfferId(offerId || ""));
    }, [dispatch, offerId]);

    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
}

export default Page;