import React, {useEffect} from "react";
import Block, {BlockSubTitle} from "../../Block";
import {Box} from "@mui/material";
import {IOfferTotal, IPassenger, PassengerView} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../store";
import {getAllPassengers} from "../../../features/passengers/passengersSlice";
import {getPassengerType, PassengerTitle} from "../utils";
import Passenger from "../Passenger";
import {fetchPaymentPassengers} from "../../../features/passengers/thunks/passenger";

const PaymentPassengers: React.FC = () => {
    const dispatch = useAppDispatch();
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const totals: IOfferTotal = useAppSelector(state => state.flightOffer.total);
    const passengersList: Array<IPassenger> = useAppSelector(getAllPassengers);

    useEffect(() => {
        offerId && dispatch(fetchPaymentPassengers());
    }, [dispatch, offerId]);

    return (
        <Block title={"Passenger details"} topRounded>
            {
                passengersList.map((item, key) => {
                    const passengerType = getPassengerType(totals, key);

                    return (
                        <React.Fragment key={item.id}>
                            <BlockSubTitle
                                title={
                                    <PassengerTitle
                                        title={`Passenger #${key + 1} ${passengerType.title}`}
                                        showCloseIcon={false}
                                    />
                                }
                                extend
                            />
                            <Box sx={{paddingBottom: 5, paddingTop: 2}}>
                                <Passenger
                                    passenger={item}
                                    passengerType={passengerType.type}
                                    passengerView={PassengerView.PaymentPassenger}
                                />
                            </Box>
                        </React.Fragment>
                    )
                })
            }
        </Block>
    );
}

export default PaymentPassengers;