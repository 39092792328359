import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFlightOffer, IOfferTotal, IStateFlightOffer} from "../../types";
import {flightOfferSliceDefaultValue} from "../../defaultValues";
import {fetchFlightOffer} from "./thunks/flightOffer";

const flightOfferAdapter = createEntityAdapter();

const initialState: IStateFlightOffer = flightOfferAdapter.getInitialState(flightOfferSliceDefaultValue);

const flightOfferSlice = createSlice({
    name: "flightOffer",
    initialState,
    reducers: {
        saveOfferId: (state: IStateFlightOffer, action: PayloadAction<string>) => {
            state.offerId = action.payload;
        },
        updateTotal: (state: IStateFlightOffer, action: PayloadAction<IOfferTotal>) => {
            state.total = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlightOffer.fulfilled, (state: IStateFlightOffer, action: PayloadAction<IFlightOffer>) => {
                state.routes = action.payload.offer.routes;
                state.total = action.payload.offer.total;
                state.total.priceDetails.travelProtectionPrice = 0;
                state.total.priceDetails.plantATreePrice = 0;
                state.total.priceDetails.expertRemunerationPrice = 0;
                state.passedTime = action.payload.offer.passedTime;
            })
    },
});

export const {saveOfferId, updateTotal} = flightOfferSlice.actions;

export default flightOfferSlice.reducer;