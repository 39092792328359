import React from "react";
import {Box} from "@mui/material";

type Props = {
    icon: string,
    iconWidth?: string,
    iconHeight?: string,
    children: React.ReactNode,
    textSx?: object,
    sx?: object
}
const Social: React.FC<Props> = props => {
    const {icon, iconWidth = "auto", iconHeight = "auto", textSx = {}, sx = {}, children} = props;
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: `${iconWidth}`,
                    height: `${iconHeight}`
                }}
            >
                <Box
                    component={"img"}
                    src={icon}
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                    alt="icon"
                />
            </Box>
            <Box
                sx={{
                    paddingLeft: '5px',
                    ...textSx
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

export default Social;