import {FC, useContext, useEffect, useState} from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment, {Moment} from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {IError, IRoute, PassengerType} from "../../../types";
import {useAppSelector} from "../../../store";
import {PassengerFunctionsContext, PassengerProps} from "./index";
import useErrors from "../../../hooks/useErrors";

const datePickerMinDate = (passengerType: PassengerType, firstFlightDate: Date): Moment => {
    if (passengerType === PassengerType.CHILD) {
        return moment(firstFlightDate).subtract(12, 'years').add(1, 'day');
    }

    if (passengerType === PassengerType.INFANT) {
        return moment(firstFlightDate).subtract(2, 'years').add(1, 'day');
    }

    return moment(firstFlightDate).subtract(120, 'years');
}

const datePickerMaxDate = (passengerType: PassengerType, firstFlightDate: Date): Moment => {
    if (passengerType === PassengerType.CHILD) {
        return moment(firstFlightDate).subtract(2, 'years');
    }

    if (passengerType === PassengerType.ADULT) {
        return moment(firstFlightDate).subtract(12, 'years');
    }

    return moment(firstFlightDate);
}

const convertFlightDateToDate = (firstFlightDate: string): Date => {
    const format = "DD MMM HH:mm";
    if (!firstFlightDate) {
        return new Date(moment().valueOf());
    }

    let startDate = moment(firstFlightDate, format, true).hours(0).minutes(0).seconds(0);
    const now = moment();

    if (startDate.valueOf() < now.valueOf()) {
        return startDate.add(1, 'year').toDate();
    } else {
        return startDate.toDate();
    }
}

const PassengerDateOfBirth: FC<PassengerProps> = props => {
    const {passenger, passengerType} = props;
    const [cleared, setCleared] = useState<boolean>(false);
    const routes: Array<IRoute> | undefined = useAppSelector(state => state.flightOffer.routes);
    const firstFlightDate: string = routes ? (routes[0]?.flights[0]?.startDate || "") : "";
    const firstFlightDateObject = convertFlightDateToDate(firstFlightDate);

    const passengersErrors: Array<Array<IError>> = useAppSelector(state => state.passengers.errors);
    const errors = passengersErrors[passenger.id];
    const {getFieldError} = useErrors(errors);

    const {handleDatePickerChange} = useContext(PassengerFunctionsContext);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {
        };
    }, [cleared]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={"Date of birth"}
                referenceDate={datePickerMaxDate(passengerType, firstFlightDateObject)}
                value={passenger.dateOfBirth ? moment(passenger.dateOfBirth) : null}
                onChange={(newValue) => {
                    if (newValue === null) {
                        handleDatePickerChange("");
                        return;
                    }

                    const date = moment(newValue);
                    if (date.isValid()) {
                        handleDatePickerChange(date.format("YYYY-MM-DD"));
                    }
                }}
                minDate={datePickerMinDate(passengerType, firstFlightDateObject)}
                maxDate={datePickerMaxDate(passengerType, firstFlightDateObject)}
                slotProps={{
                    textField: {
                        helperText: getFieldError("dateOfBirth"),
                        error: !!getFieldError("dateOfBirth"),
                        variant: "outlined"
                    },
                    field: {clearable: true, onClear: () => setCleared(true)}
                }}
            />
        </LocalizationProvider>
    )
}

export default PassengerDateOfBirth;