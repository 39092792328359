import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStateRate} from "../../types";
import {rateSliceDefaultValue} from "../../defaultValues";
import {getLocalRate, updateLocalRate} from "./thunks/rate";

const rateAdapter = createEntityAdapter();

const initialState: IStateRate = rateAdapter.getInitialState(rateSliceDefaultValue);

const rateSlice = createSlice({
    name: "rate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLocalRate.fulfilled, (state: IStateRate, action: PayloadAction<number>) => {
                state.rate = action.payload;
            })
            .addCase(updateLocalRate.fulfilled, (state: IStateRate, action: PayloadAction<number>) => {
                state.rate = action.payload;
            })
    },
});

export default rateSlice.reducer;