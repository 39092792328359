import {CircleArrowDownIcon, CircleArrowUpIcon} from "../../Icons";
import React from "react";

type Props = {
    open: boolean
}
const SummaryOpenIcon: React.FC<Props> = (props) => {
    const {open} = props;
    return (
        open ? <CircleArrowDownIcon/> : <CircleArrowUpIcon/>
    );
}

export default SummaryOpenIcon;