import {createTheme, Theme} from "@mui/material";
import {CheckboxCheckedIcon, CheckboxIcon, RadioCheckedIcon, RadioIcon, SelectIcon} from "./components/Icons";

export const colors: any = {
    white: "white",
    primaryTextColor: "#11172A",
    darkBackground: "#11172A",
    grayBackground: "#E8EDF3",
    liteGrayBackground: "#F3F8FD",
    red: "#E31F3D",
    secondDarkBackground: "#1E2848",
    gray: "#9BA3BE",
    errorBorderColor: "#DF1F3D",
    errorBackgroundColor: "#FFE8E8",
    footerTextColor: "#E5E5E5"
}

const theme: Theme = createTheme({
    typography: {
        fontFamily: "PT Root UI",
        htmlFontSize: 10
    },
    palette: {
        text: {
            primary: colors.primaryTextColor
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                html: {
                    fontSize: "62.5%",
                    [theme.breakpoints.down('lg')]: {
                        fontSize: "58%"
                    },
                    [theme.breakpoints.down('sm')]: {
                        fontSize: "53.5%"
                    }
                }
            }),
        },
        MuiContainer: {
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.between('md', 'lg')]: {
                        paddingLeft: 16,
                        paddingRight: 16
                    }
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({theme}) =>
                    theme.unstable_sx({
                        backgroundColor: colors.red,
                        borderRadius: 2,
                        color: colors.white,
                        fontSize: "2.1rem",
                        fontWeight: 700,
                        paddingX: 5,
                        paddingY: 1.5,
                        textTransform: "none",
                        '&:hover': {
                            backgroundColor: colors.gray
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#ececec',
                        }
                    })
            },
            variants: [
                {
                    props: {variant: 'add-button'},
                    style: {
                        backgroundColor: colors.white,
                        padding: 0,
                        color: 'inherit',
                        fontWeight: 500,
                        fontSize: "1.8rem",
                        '.MuiButton-startIcon': {
                            marginLeft: 0
                        },
                        '&:hover': {
                            backgroundColor: "transparent"
                        }
                    }
                },
                {
                    props: {variant: 'cancel'},
                    style: {
                        backgroundColor: "transparent",
                        color: 'inherit',
                        '&:hover': {
                            backgroundColor: "transparent"
                        }
                    }
                },
            ]
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({theme}) =>
                    theme.unstable_sx({
                        color: colors.primaryTextColor,
                        fontSize: "1.8rem",
                        fontWeight: 700,
                        '&.Mui-focused': {
                            color: colors.primaryTextColor
                        }
                    })
            }
        },
        MuiRadio: {
            defaultProps: {
                checkedIcon: <RadioCheckedIcon htmlColor={colors.gray}/>,
                icon: <RadioIcon htmlColor={colors.gray}/>
            }
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon: <CheckboxCheckedIcon htmlColor={colors.gray}/>,
                icon: <CheckboxIcon htmlColor={colors.gray}/>
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    '&.Mui-focused, &:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: "#DEE5EC"
                        }
                    },
                    '&.Mui-error': {
                        borderColor: colors.errorBorderColor,
                        backgroundColor: colors.errorBackgroundColor
                    }
                },
                notchedOutline: {
                    borderColor: "#DEE5EC",
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    fontSize: "2.8rem",
                    textAlign: "center",
                    color: colors.secondDarkBackground,
                    backgroundColor: colors.liteGrayBackground,
                    paddingRight: 40,
                    position: "relative",
                    '& .MuiIconButton-root': {
                        color: colors.secondDarkBackground,
                        top: "calc(50% - 20px)"
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: (props) => <SelectIcon {...props} />
            },
            styleOverrides: {
                icon: {
                    right: '13px',
                    top: "calc(50% - 3px)"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: "1.6rem",
                    color: colors.gray
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <SelectIcon/>
            },
            styleOverrides: {
                popupIndicator: {
                    right: '4px'
                },
                popupIndicatorOpen: {
                    right: '4px'
                }
            }
        }
    }
});

export default theme;

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        'add-button': true,
        'cancel': true
    }
}