import React from "react";
import Flight from "./Flight";
import {IFlight} from "../../types";

type Props = {
    flights: Array<IFlight>
}
const Route: React.FC<Props> = props => {
    const {flights} = props;

    return (
        <>
            {flights.map((flight, key) =>
                <Flight flight={flight} key={key}/>
            )}
        </>
    );
}

export default React.memo(Route);