import {IStateFlightOffer} from "../../../types";
import {rateSliceDefaultValue} from "../../../defaultValues";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_RATE} from "../../../constants";

const getRateFromStorage = async (offerId: string): Promise<number> => {
    let localRate: string | null = sessionStorage.getItem(STORAGE_RATE(offerId));
    let rate: number = rateSliceDefaultValue.rate;

    if (!localRate) {
        setRateInStorage(offerId, rate);
    } else {
        rate = parseInt(localRate);
    }
    return rate;
}

const setRateInStorage = (offerId: string, rate: number): void => {
    sessionStorage.setItem(STORAGE_RATE(offerId), rate.toString());
}

export const getLocalRate = createAsyncThunk<number, void, {
    state: { flightOffer: IStateFlightOffer }
}>('rate/getLocalRate', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        return await getRateFromStorage(flightOffer.offerId);
    }

    return rateSliceDefaultValue.rate;
});

export const updateLocalRate = createAsyncThunk<number, number, {
    state: { flightOffer: IStateFlightOffer }
}>('rate/updateLocalRate', async (rate: number, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        await getRateFromStorage(flightOffer.offerId);
        setRateInStorage(flightOffer.offerId, rate);
    }

    return rate;
});