import React, {createContext, FC} from 'react';
import './App.css';
import theme from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Book from "./Pages/Book";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Confirmation from "./Pages/Confirmation";
import Page from "./components/Page";
import Payment from "./Pages/Payment";

export const StepContext = createContext(1);

const App: FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="booking/:offerId"
                        element={
                            <StepContext.Provider value={2}>
                                <Page><Book/></Page>
                            </StepContext.Provider>
                        }
                    />
                    <Route
                        path="payment/:offerId"
                        element={
                            <StepContext.Provider value={3}>
                                <Page><Payment/></Page>
                            </StepContext.Provider>
                        }
                    />
                    <Route
                        path="confirmation/:offerId"
                        element={
                            <StepContext.Provider value={4}>
                                <Page><Confirmation/></Page>
                            </StepContext.Provider>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;