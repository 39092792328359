import {IPassenger, IStateFlightOffer, IStatePassengers} from "../../../types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getPassengers as getPassengersFromServer, savePassengers as savePassengersToServer} from "../../../request";
import {passengerDefaultValue} from "../../../defaultValues";
import {STORAGE_PASSENGERS} from "../../../constants";

const getPassengersFromStorage = async (offerId: string): Promise<Array<IPassenger>> => {
    let passengersData: string | null = sessionStorage.getItem(STORAGE_PASSENGERS(offerId));
    let passengersDataList: Array<IPassenger> = [passengerDefaultValue];

    if (!passengersData) {
        setPassengersInStorage(offerId, passengersDataList);
    } else {
        passengersDataList = JSON.parse(passengersData);
    }
    return passengersDataList;
}

const setPassengersInStorage = (offerId: string, passengers: Array<IPassenger>): void => {
    sessionStorage.setItem(STORAGE_PASSENGERS(offerId), JSON.stringify(passengers));
}

export const addLocalPassenger = createAsyncThunk<IPassenger, void, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/addLocalPassenger', async (_, {getState}) => {
    const {flightOffer} = getState();

    let passengers = await getPassengersFromStorage(flightOffer.offerId);

    const passengerIDs: Array<number> = passengers.map(item => item.id);
    const lastID: number = Math.max(...passengerIDs);
    const newPassenger = {...passengerDefaultValue, id: lastID + 1};

    passengers.push(newPassenger);
    setPassengersInStorage(flightOffer.offerId, passengers);

    return newPassenger;
});

export const updateLocalPassenger = createAsyncThunk<IPassenger, IPassenger, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/updateLocalPassenger', async (passenger: IPassenger, {getState}) => {
    const {flightOffer} = getState();
    let passengers = await getPassengersFromStorage(flightOffer.offerId);

    const foundPassengerIndex = passengers.findIndex(item => item.id === passenger.id);
    passengers[foundPassengerIndex] = passenger;
    setPassengersInStorage(flightOffer.offerId, passengers);

    return passenger;
});

export const deleteLocalPassenger = createAsyncThunk<IPassenger, IPassenger, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/deleteLocalPassenger', async (passenger: IPassenger, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        let passengers = await getPassengersFromStorage(flightOffer.offerId);

        if (passengers.length > 1) {
            passengers = passengers.filter(item => item.id !== passenger.id);
            setPassengersInStorage(flightOffer.offerId, passengers);
        }
    }

    return passenger;
});

export const savePassengers = createAsyncThunk<void, void, {
    state: { passengers: IStatePassengers, flightOffer: IStateFlightOffer }
}>('passengers/savePassengers', async (_, {getState}) => {
    const {passengers, flightOffer} = getState();
    await savePassengersToServer(flightOffer.offerId, passengers.passengers);
});

export const fetchPaymentPassengers = createAsyncThunk<Array<IPassenger>, void, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/fetchPaymentPassengers', async (_, {getState}) => {
    const {flightOffer} = getState();

    let passengers = [passengerDefaultValue];
    if (flightOffer.offerId) {
        const serverPassengers = await getPassengersFromServer(flightOffer.offerId);
        if (serverPassengers?.length > 0) {
            setPassengersInStorage(flightOffer.offerId, serverPassengers);
            passengers = serverPassengers;
        } else {
            passengers = await getPassengersFromStorage(flightOffer.offerId);
        }
    }

    return passengers;
});