import React from "react";
import {Box, Container, Grid, Link, useMediaQuery} from "@mui/material";
import theme, {colors} from "../../../theme";
import {useAppSelector} from "../../../store";
import {IExpert, MenuLink} from "../../../types";
import {cdn} from "../../../util";

const pages: Array<MenuLink> = [
    {text: "About", url: "https://www.priorityflyers.com/about-us"},
    {text: "Reviews", url: "https://www.priorityflyers.com/reviews"},
    {text: "Privacy Policy", url: "https://www.priorityflyers.com/page-privacy-policy"},
    {text: "Terms & Conditions", url: "https://www.priorityflyers.com/page-terms-and-conditions"}
];

const HeaderMenu: React.FC = props => {
    const expert: IExpert | undefined = useAppSelector((state) => state.expert.expert);
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));

    return (

        <Container maxWidth={"xl"} sx={{
            height: 95
        }}>
            <Grid container sx={{
                display: "flex",
                alignItems: "center",
                height: "100%"
            }}>
                <Grid item xs={4} md={2}>
                    <Box component="img"
                         src={cdn(matchesSM ? "/images/logo.gif" : "/images/logo-sm.gif")}
                         sx={{maxWidth: 200, width: "100%"}}
                         alt={"Logo"}/>
                </Grid>
                <Grid item md={5} sx={{
                    display: {md: "flex", xs: "none"},
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    columnGap: 1
                }}>
                    {pages.map((item, index) =>
                        <Link
                            underline={"none"}
                            color={"inherit"}
                            key={index}
                            href={item.url}
                            rel="noreferrer"
                            target={"_blank"}
                            sx={{
                                fontWeight: 700
                            }}>
                            {item.text}
                        </Link>
                    )}
                </Grid>
                <Grid item xs={8} md={5}>
                    {expert && <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }}>
                        <Box sx={{
                            display: {xs: "none", md: "block"},
                            borderRight: "1px solid",
                            paddingRight: {xs: 3, md: 1, lg: 3},
                            textAlign: "end"
                        }}>
                            <Box sx={{
                                fontSize: "1.4rem",
                                wordBreak: {xs: "break-all", lg: "initial"}
                            }}>
                                {expert.email}
                            </Box>
                            <Box sx={{
                                color: colors.red,
                                fontWeight: 700
                            }}>
                                {expert.phone}
                            </Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <Box sx={{
                                paddingLeft: {xs: 3, md: 1, lg: 3},
                                textAlign: "end"
                            }}>
                                <Box sx={{
                                    fontSize: "1.4rem"
                                }}>
                                    Travel Agent
                                </Box>
                                <Box sx={{
                                    fontWeight: 700
                                }}>
                                    {expert.name}
                                </Box>
                            </Box>
                            <Box sx={{marginLeft: {xs: 1, lg: 2}}}>
                                <Box component="img"
                                     src={expert.picture || cdn("/images/expert.png")}
                                     alt={"avatar"}
                                     sx={{borderRadius: "50%", width: 65}}
                                />
                            </Box>
                        </Box>
                    </Box>}
                </Grid>
            </Grid>
        </Container>
    );
}

export default React.memo(HeaderMenu);