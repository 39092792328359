import {IPassenger, IReview, ISaveOffer, Method} from "./types";
import {PAYMENT_STORE_API_URL} from "./settings";


// const GET_OFFER = (offerId: string): string => `${API_URL}/public/offers/${offerId}`;
const GET_OFFER = (offerId: string): string => `${PAYMENT_STORE_API_URL}/offers/${offerId}`;
const PUT_OFFER = (offerId: string): string => `${PAYMENT_STORE_API_URL}/offers/${offerId}`;
const POST_PASSENGERS = (offerId: string): string => `${PAYMENT_STORE_API_URL}/offers/${offerId}/passengers/batch`;
const GET_PASSENGERS = (offerId: string): string => `${PAYMENT_STORE_API_URL}/offers/${offerId}/passengers`;
const POST_REVIEW = (offerId: string): string => `${PAYMENT_STORE_API_URL}/offers/${offerId}/reviews`;

const fetchCall = async (url: string, method: Method, data?: any/*, callback: Function | null*/): Promise<any> => {
    let options: RequestInit = {
        method: method,
        mode: 'cors'
    }

    if (([Method.POST, Method.PUT] as Array<string>).includes(method) && data) {
        options['headers'] = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
        options['body'] = JSON.stringify(data)
    }

    return await fetch(url, options)
        .then(res => {
            const contentType = res.headers.get("content-type");
            if (contentType?.includes("application/json")) {
                return res.json();
            }
        })
    // .then(
    //     (result) => {
    //         callback && callback(result);
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {
    //         //setIsLoaded(true);
    //         //setError(error);
    //     }
    // )
}

export const getOffer = (bookId: string/*, callback: Function*/) => fetchCall(GET_OFFER(bookId), Method.GET/*, callback*/)
export const saveOffer = (bookId: string, offer: ISaveOffer) => fetchCall(PUT_OFFER(bookId), Method.PUT, offer)
export const getPassengers = (bookId: string) => fetchCall(GET_PASSENGERS(bookId), Method.GET)
export const savePassengers = (bookId: string, passengers: Array<IPassenger>) => fetchCall(POST_PASSENGERS(bookId), Method.POST, passengers/*, callback*/)
export const saveReview = (bookId: string, review: IReview) => fetchCall(POST_REVIEW(bookId), Method.POST, review/*, callback*/)

export default fetchCall;