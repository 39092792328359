import {IStateFlightOffer} from "../../../types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_EXPERT_REMUNERATION} from "../../../constants";

const setExpertRemunerationInStorage = (offerId: string, remuneration: number): void => {
    sessionStorage.setItem(STORAGE_EXPERT_REMUNERATION(offerId), remuneration.toString());
}

export const getLocalExpertRemuneration = createAsyncThunk<number | null, void, {
    state: { flightOffer: IStateFlightOffer }
}>('expert/getLocalExpertRemuneration', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        let localAmount: string | null = sessionStorage.getItem(STORAGE_EXPERT_REMUNERATION(flightOffer.offerId));
        if (localAmount != null) {
            const intLocalAmount = parseInt(localAmount);
            if (!Number.isNaN(intLocalAmount)) {
                return intLocalAmount;
            }
        }
    }

    return null;
});

export const updateLocalExpertRemuneration = createAsyncThunk<number, number, {
    state: { flightOffer: IStateFlightOffer }
}>('expert/updateLocalExpertRemuneration', async (remuneration: number, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        setExpertRemunerationInStorage(flightOffer.offerId, remuneration);
    }

    return remuneration;
});