import React from "react";

import {Box} from "@mui/material";

type Props = {
    children?: React.ReactNode;
    color: string,
    topRounded?: boolean
    bottomRounded?: boolean
};

const ColoredBox: React.FC<Props> = props => {
    const {children, color, topRounded, bottomRounded} = props;
    return (
        <Box sx={{
            backgroundColor: color,
            borderTopLeftRadius: topRounded ? 9 : "unset",
            borderTopRightRadius: topRounded ? 9 : "unset",
            borderBottomLeftRadius: bottomRounded ? 9 : "unset",
            borderBottomRightRadius: bottomRounded ? 9 : "unset"
        }}>
            {children}
        </Box>
    )
}

export default ColoredBox;