import React, {ReactNode} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ClickEventHandler} from "../../types";

type Props = {
    children: ReactNode | string,
    title: ReactNode | string,
    open: boolean,
    onClose: ClickEventHandler
}
const ModalWindow: React.FC<Props> = props => {
    const {children, title, onClose, open} = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    //onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={handleClose}>Cancel</Button>*/}
            {/*    <Button onClick={handleClose}>Subscribe</Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    )
}

export default ModalWindow;