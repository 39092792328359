import Block from "../Block";
import {Box, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography} from "@mui/material";
import React, {ReactElement, useEffect} from "react";
import {cdn, formatPrice, siteUrl} from "../../util";
import {PaymentGrayBox} from "../../Pages/Payment";
import RedText from "../RedText";
import {ChangeEvent, IOfferTotal, IStateTravelProtection} from "../../types";
import {useAppDispatch, useAppSelector} from "../../store";
import {
    getLocalTravelProtection,
    updateLocalTravelProtection
} from "../../features/travelProtection/thunks/travelProtection";
import {setTravelProtectionSum} from "../../features/travelProtection/travelProtectionSlice";

type CheckedItems = {
    question: string,
    affirmation: string
}
const checkedItems: Array<CheckedItems> = [
    {
        question: "Missed your connections due to inclement weather?",
        affirmation: "We’ll rebook you for FREE!"
    },
    {
        question: "Last minute plan changes?",
        affirmation: "We’ll process your changes for FREE!"
    },
    {
        question: "Got sick and can’t travel?",
        affirmation: "Get 100% refund"
    },
    {
        question: "Flights got canceled or delayed?",
        affirmation: "We’ll rebook you for FREE!"
    },
    {
        question: "Never used your Travel Care Service?",
        affirmation: "No worries! Claim Your Voucher for next purchase with Priority Flyers"
    },
    {
        question: "Airline mishandled your baggage?",
        affirmation: "We’ll get them back"
    },
]

const travelProtectionPercent = 7;
const calculateTravelProtectionSum = (totalPrice: number): number => {
    return Math.round(totalPrice * travelProtectionPercent) / 100;
}

const siteLink = (link: string, content: ReactElement) =>
    <Link href={siteUrl(link)} sx={{textDecoration: "none"}}>
        {content}
    </Link>

const TravelProtection: React.FC = props => {
    const dispatch = useAppDispatch();
    const travelProtection: IStateTravelProtection = useAppSelector(state => state.travelProtection);
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const total: IOfferTotal | undefined = useAppSelector(state => state.flightOffer.total);
    const travelProtectionSum = formatPrice(travelProtection.sum, total?.priceDetails.currency || "USD")

    useEffect(() => {
        if (offerId) {
            dispatch(getLocalTravelProtection());
        }
    }, [dispatch, offerId]);

    useEffect(() => {
        const travelProtectionSum = calculateTravelProtectionSum(total?.priceDetails.price || 0);
        dispatch(setTravelProtectionSum(travelProtectionSum));
    }, [dispatch, total?.priceDetails.price]);

    const handleChange = (e: ChangeEvent) => {
        dispatch(updateLocalTravelProtection(e.target.value === "true"));
    };

    return (
        <>
            <Block title={"Travel protection"} topRounded>
                <Box sx={{paddingY: 1}}>
                    <PaymentGrayBox sx={{marginTop: 1}}>
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "2.8rem",
                                fontWeight: 700
                            }}
                        >
                            Why to protect?<br/>
                            We’ll process your changes for free!
                        </Typography>
                        <Box
                            component={"img"}
                            src={cdn("/images/travel-protection.svg")}
                        />
                    </PaymentGrayBox>
                </Box>
                <Box
                    sx={{
                        marginBottom: 3,
                        marginTop: 4
                    }}
                >
                    <Grid container rowSpacing={4}>
                        {checkedItems.map((item, index) =>
                            <Grid
                                key={index}
                                item xs={12}
                                md={6}
                            >
                                <Box
                                    sx={{
                                        paddingLeft: {xs: 1, sm: 3},
                                        display: "flex",
                                        columnGap: 1
                                    }}
                                >
                                    <Box
                                        component={"img"}
                                        src={cdn("/images/comment.png")}
                                        sx={{
                                            marginTop: "5px",
                                            height: "100%"
                                        }}
                                    />
                                    <Box>
                                        <Typography>{item.question}</Typography>
                                        <Typography sx={{fontWeight: 700}}>{item.affirmation}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>

                <Box sx={{paddingBottom: 1}}>
                    <PaymentGrayBox sx={{flexDirection: {xs: "column", sm: "row"}, rowGap: 3}}>
                        <Box
                            component={"img"}
                            src={cdn("/images/air-help.svg")}
                        />
                        <Typography
                            component="span"
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            Get up to $700 per passenger for delayed or canceled flights, no matter the ticket price.
                        </Typography>
                    </PaymentGrayBox>
                </Box>
            </Block>

            <Box sx={{height: 8}}></Box>

            <Block bottomRounded>
                <Box sx={{paddingX: {xs: 1, md: 3}, paddingY: 4}}>
                    <Box component={"img"} src={cdn("/images/recommended.svg")} sx={{mb: 3}}/>
                    <FormControl>
                        <RadioGroup
                            value={travelProtection.state}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio/>}
                                componentsProps={{typography: {sx: {fontWeight: 700}}}}
                                label={
                                    <>
                                        Yes, I would like to add Travel Protection to my purchase
                                        for {travelProtectionSum} and I confirm that I have read and agreed to
                                        the {siteLink("/page-terms-and-conditions", <RedText>Terms and
                                        Conditions</RedText>)}
                                    </>
                                }
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio/>}
                                componentsProps={{typography: {sx: {fontWeight: 700}}}}
                                label={
                                    <>
                                        No, I choose not to protect. {siteLink("/", <RedText>Read More</RedText>)}
                                    </>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Block>
        </>
    );
}

export default TravelProtection;