import {FC, ReactNode} from "react";
import {Box, Container, Grid} from "@mui/material";
import {colors} from "../../theme";
import ColoredBox from "../ColoredBox";

type Props = {
    title: string,
    leftColumn: ReactNode,
    rightColumn: ReactNode
}
const Page2Columns: FC<Props> = (props) => {
    const {title, leftColumn, rightColumn} = props
    return (
        <>
            <Box sx={{
                fontSize: "3.8rem",
                textAlign: "center",
                fontWeight: 600,
                paddingY: {xs: 2, lg: 3}
            }}>
                {title}
            </Box>
            <ColoredBox color={colors.grayBackground}>
                <Container maxWidth={"xl"} sx={{
                    paddingTop: {xs: 4, lg: 5},
                    paddingBottom: {xs: 2, lg: 9}
                }}>
                    <Grid container columnSpacing={{xs: 0, md: 2, lg: 5}} rowSpacing={{xs: 5, md: 0}}>
                        <Grid item xs={12} md={8}>
                            {leftColumn}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {rightColumn}
                        </Grid>
                    </Grid>
                </Container>
            </ColoredBox>
        </>
    );
}

export default Page2Columns;