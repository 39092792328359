import React from "react";
import HeaderMenu from "./HeaderMenu";
import SubHeader from "./SubHeader";

const Header: React.FC = props => {
    return (
        <>
            <HeaderMenu/>
            <SubHeader/>
        </>
    );
}

export default Header;