import React from "react";

import {Box, SxProps} from "@mui/material";
import {colors} from "../../theme";

type Props = {
    children?: React.ReactNode,
    title?: React.ReactNode,
    subTitle?: React.ReactNode,
    topRounded?: boolean
    bottomRounded?: boolean
    sx?: SxProps
};

const Block: React.FC<Props> = props => {
    const {children, title, subTitle, topRounded, bottomRounded, sx = {}} = props;
    return (
        <>
            {title && <BlockTitle title={title} topRounded={topRounded}/>}
            {subTitle && <BlockSubTitle title={subTitle}/>}

            <Box sx={{
                borderTopLeftRadius: topRounded && !title ? 9 : "unset",
                borderTopRightRadius: topRounded && !title ? 9 : "unset",
                borderBottomLeftRadius: bottomRounded ? 9 : "unset",
                borderBottomRightRadius: bottomRounded ? 9 : "unset",
                backgroundColor: colors.white,
                paddingX: {xs: 1, sm: 3, md: 1, lg: 3},
                ...sx
            }}>
                {children}
            </Box>
        </>
    )
}

type TitleProps = {
    title: React.ReactNode,
    topRounded?: boolean
};
const BlockTitle: React.FC<TitleProps> = props => {
    const {title, topRounded} = props;
    return (
        <Box sx={{
            color: colors.white,
            backgroundColor: colors.darkBackground,
            fontSize: "2.8rem",
            fontWeight: 700,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingY: 1,
            paddingX: {xs: 2, lg: 3},
            minHeight: 60,
            borderTopLeftRadius: topRounded ? 9 : "unset",
            borderTopRightRadius: topRounded ? 9 : "unset",
            textAlign: "center"
        }}>
            {title}
        </Box>
    );
}

type SubTitleProps = {
    title: React.ReactNode,
    extend?: boolean
};
export const BlockSubTitle: React.FC<SubTitleProps> = props => {
    const {title, extend} = props;
    return (
        <Box sx={{
            backgroundColor: colors.secondDarkBackground,
            paddingY: 1,
            paddingX: 3,
            minHeight: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginX: extend ? {xs: -1, sm: -3, md: -1, lg: -3} : "unset"
        }}>
            {typeof title === "string" ?
                <Box component="span" sx={{
                    fontSize: "1.8rem",
                    color: colors.white,
                    fontWeight: 700
                }}>
                    {title}
                </Box> :
                <>{title}</>
            }
        </Box>
    )
}

export default Block;