import React, {useContext} from "react";
import {Container, Grid, Typography} from "@mui/material";
import Steps from "./Steps";
import {colors} from "../../../theme";
import ColoredBox from "../../ColoredBox";
import {StepContext} from "../../../App";

const SubHeader: React.FC = () => {
    const step = useContext<number>(StepContext);

    return (
        <ColoredBox color={colors.darkBackground}>
            <Container maxWidth={"xl"} sx={{
                paddingY: {xs: 2, lg: 4},
                color: colors.white
            }}>
                <Grid container>
                    <Grid item xs={12} md={7} sx={{textAlign: {xs: "center", md: "start"}}}>
                        <Typography variant={'h4'} sx={{marginY: 1, fontWeight: 600, fontSize: "2.8rem"}}>
                            Please, choose the flight and check passenger details
                        </Typography>
                        <Typography variant={"h5"} sx={{marginY: 1, fontWeight: 600, fontSize: "2.1rem"}}>
                            Tickets at a good price are almost in your hands!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{marginTop: {xs: 5, md: 1}}}>
                        <Steps step={step}/>
                    </Grid>
                </Grid>
            </Container>
        </ColoredBox>
    );
}

export default React.memo(SubHeader);//add second argument related to step number