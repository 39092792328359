declare global {
    interface Window {
        envData?: Settings;
    }
}

type Settings = {
    PAYMENT_STORE_API_URL: string,
    CDN_URL: string,
    SITE_URL: string
}


const env: Settings | undefined = window.envData;

export const PAYMENT_STORE_API_URL: string = env?.PAYMENT_STORE_API_URL ?? "";
export const CDN_URL: string = env?.CDN_URL ?? "";
export const SITE_URL: string = env?.SITE_URL ?? "";

delete window.envData;