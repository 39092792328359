import React from "react";
import {Box} from "@mui/material";
import {cdn} from "../../util";

const cards: Array<string> = [
    "visa", "master", "discover", "amex"
];

const CardTypes: React.FC = () => {
    return (
        <>
            {cards.map(item =>
                <Box
                    key={item}
                    component={"img"}
                    src={cdn(`/images/${item}.svg`)}
                    sx={{marginX: 1}}
                    alt={item}
                />
            )}
        </>
    );
}

export default CardTypes