import {
    Gender,
    ICardInformation,
    IPassenger,
    IPassengerContact,
    IStateBillingAddress,
    IStateCardInformation,
    IStateExpert,
    IStateFlightOffer,
    IStateModal,
    IStatePassengers,
    IStatePaymentConfirmation,
    IStatePlantATree,
    IStatePurchaseSummary,
    IStateRate,
    IStateTravelProtection
} from "./types";

export const passengerDefaultValue: IPassenger = {
    dateOfBirth: "",
    gender: Gender.MALE,
    firstName: "",
    lastName: "",
    middleName: "",
    id: 0,
    redressNumber: "",
    seatPreference: "",
    mealPreference: "",
    specialAssistance: "",
    programNumbers: []
};

export const passengerContactDefaultValue: IPassengerContact = {
    email: "",
    phone: ""
}

export const passengersSliceDefaultValue: IStatePassengers = {
    passengers: [passengerDefaultValue],
    errors: [],
    contact: passengerContactDefaultValue
}

export const expertSliceDefaultValue: IStateExpert = {
    expert: undefined,
    remuneration: {
        amount: 0,
        confirmed: false
    }
}

export const flightOfferSliceDefaultValue: IStateFlightOffer = {
    routes: [],
    total: {
        adults: 0,
        adultsPrice: "",
        children: 0,
        childrenPrice: "",
        newborns: 0,
        newbornsPrice: "",
        price: "",
        priceDetails: {
            price: 0,
            travelProtectionPrice: 0,
            plantATreePrice: 0,
            expertRemunerationPrice: 0,
            currency: "USD"
        }
    },
    passedTime: 0,
    offerId: ""
}

export const modalSliceDefaultValue: IStateModal = {
    cardCVVModal: false,
    cardPhoneModal: false
}

export const rateSliceDefaultValue: IStateRate = {
    rate: 0
}

export const plantATreeSliceDefaultValue: IStatePlantATree = {
    amount: 0,
    confirmed: false
}

export const travelProtectionSliceDefaultValue: IStateTravelProtection = {
    state: false,
    sum: 0
}

export const billingDetailsSliceDefaultValue: IStateBillingAddress = {
    billingDetails: {
        country: "",
        state: "",
        city: "",
        street: "",
        zip: "",
        email: "",
        phone: ""
    },
    errors: []
};

export const cardInformationSliceDefaultValue: IStateCardInformation = {
    cardInformation: {
        number: "",
        expMonth: null,
        expYear: null,
        cvv: "",
        name: "",
        phone: ""
    } as ICardInformation,
    errors: []
};

export const paymentConfirmationSliceDefaultValue: IStatePaymentConfirmation = {
    confirmed: false
}

export const purchaseSummarySliceDefaultValue: IStatePurchaseSummary = {
    isOpen: false
}