import React from "react";
import {colors} from "../../theme";
import {Box} from "@mui/material";
import Block from "../Block";
import {Direction, IRoute} from "../../types";
import Route from "./Route";
import {useAppSelector} from "../../store";
import {cdn} from "../../util";
import Social from "../Social";

const Routes: React.FC = () => {
    const routes: Array<IRoute> | undefined = useAppSelector(state => state.flightOffer.routes);
    return (
        <>
            {routes && routes.map((route, key) =>
                <Block
                    title={
                        <Title
                            direction={route.direction === "departure" ? Direction.Departure : Direction.Retour}
                        />
                    }
                    subTitle={<SubTitle route={route}/>}
                    topRounded={key === 0}
                    bottomRounded={key === routes.length - 1}
                    key={key}
                >
                    <Box sx={{paddingTop: 2}}>
                        <Route flights={route.flights}/>
                    </Box>
                </Block>
            )}
        </>
    );
}

type TitleProps = {
    direction: Direction
}
const Title: React.FC<TitleProps> = (props) => {
    const {direction} = props;

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Box component={"img"} src={cdn("/images/arrow-" + direction + ".png")} sx={{width: 20, height: 20}}
                 alt="arrow"/>
            <Box component={"span"}>
                {direction === Direction.Departure ? 'Departure' : 'Return'}
            </Box>
            <Box component={"img"} src={cdn("/images/arrow-" + direction + ".png")} sx={{width: 20, height: 20}}
                 alt="arrow"/>
        </Box>
    )
}

type SubTitleProps = {
    route: IRoute
}
const SubTitle: React.FC<SubTitleProps> = props => {
    const {startLocationCode, endLocationCode, startLocationName, endLocationName, airTime} = props.route;

    return (
        <Box sx={{
            display: {xs: "block", sm: "flex"},
            justifyContent: "space-between",
            alignItems: "center",
            color: colors.white,
            width: "100%"
        }}>
            <Box component={"span"} sx={{
                fontSize: "1.8rem",
                fontWeight: 700,
                display: {xs: "block", sm: "inline"},
                textAlign: "center"
            }}>
                {startLocationName} ({startLocationCode}) - {endLocationName} ({endLocationCode})
            </Box>
            <Box>
                <Social
                    icon={cdn("/images/time.png")}
                    iconWidth={"12px"}
                    textSx={{fontSize: "1.2rem"}}
                    sx={{justifyContent: "center"}}
                >
                    Total travel time:<strong>&nbsp;{airTime}</strong>
                </Social>
            </Box>
        </Box>
    )
}

export default React.memo(Routes);