import {IPassengerContact, IStateFlightOffer} from "../../../types";
import {passengerContactDefaultValue} from "../../../defaultValues";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_PASSENGER_CONTACT} from "../../../constants";

const getPassengerContactFromStorage = async (offerId: string): Promise<IPassengerContact> => {
    let localContact: string | null = sessionStorage.getItem(STORAGE_PASSENGER_CONTACT(offerId));
    let passengerContact: IPassengerContact = passengerContactDefaultValue;

    if (!localContact) {
        setPassengerContactInStorage(offerId, passengerContact);
    } else {
        passengerContact = JSON.parse(localContact);
    }
    return passengerContact;
}

const setPassengerContactInStorage = (offerId: string, passengerContact: IPassengerContact): void => {
    sessionStorage.setItem(STORAGE_PASSENGER_CONTACT(offerId), JSON.stringify(passengerContact));
}

export const fetchLocalPassengerContact = createAsyncThunk<IPassengerContact, void, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/fetchLocalPassengerContact', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        return getPassengerContactFromStorage(flightOffer.offerId);
    }

    return passengerContactDefaultValue;
});

export const updateLocalPassengerContact = createAsyncThunk<IPassengerContact, IPassengerContact, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/updateLocalPassengerContact', async (contactPerson, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        await getPassengerContactFromStorage(flightOffer.offerId);
        setPassengerContactInStorage(flightOffer.offerId, contactPerson);
    }

    return contactPerson;
});