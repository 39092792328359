import React, {useEffect, useState} from "react";

import {Box, Button} from "@mui/material";
import ColoredBox from "../../components/ColoredBox";
import {colors} from "../../theme";
import Passengers from "../../components/Passengers";
import Routes from "../../components/Routes";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store";
import {ClickEvent, ClickEventHandler, IOfferTotal, IPassenger} from "../../types";
import {addPassengerErrors, getAllPassengers} from "../../features/passengers/passengersSlice";
import {validatePassengers} from "../../components/Passengers/validation";
import {MAX_BOOKING_TIME} from "../../constants";
import {fetchFlightOffer} from "../../features/flight-offer/thunks/flightOffer";
import {savePassengers} from "../../features/passengers/thunks/passenger";
import Page2Columns from "../../components/Page/Page2Columns";

const headerStyle = {
    fontSize: "3.8rem",
    textAlign: "center",
    fontWeight: 600,
    paddingY: {xs: 2, md: 3}
};

const Book: React.FC = () => {
    const navigate = useNavigate();
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const routes = useAppSelector(state => state.flightOffer.routes);
    const dispatch = useAppDispatch();
    const totals: IOfferTotal | undefined = useAppSelector(state => state.flightOffer.total);
    const passedTime: number = useAppSelector(state => state.flightOffer.passedTime);
    const passengersList: Array<IPassenger> = useAppSelector(getAllPassengers);

    const bookingValid = passedTime <= MAX_BOOKING_TIME;

    useEffect(() => {
        if (bookingValid && offerId) {
            try {
                dispatch(fetchFlightOffer()).unwrap();
            } catch (e: any) {
                console.error(e)
                alert("Error on load offers");
            }
        }
    }, [dispatch, offerId]);


    const submitPassengers = async () => {
        if (offerId) {
            try {
                await dispatch(savePassengers()).unwrap();
                navigate(`/payment/${offerId}`);
            } catch (e: any) {
                console.error(e)
                alert("Error on save passengers");
            }
        }
    }

    const submitBooking = async (e: ClickEvent) => {
        const errors = validatePassengers(passengersList);
        dispatch(addPassengerErrors(errors));
        if (errors.length) {
            const firstErrorId = errors.findIndex(e => typeof e !== 'undefined');
            document.getElementById(`passenger-${firstErrorId}`)?.scrollIntoView({behavior: "smooth"})
            return;
        }
        await submitPassengers();
    }

    return (
        <>
            {bookingValid && offerId && routes.length > 0 && <>
                <Page2Columns title={"Flight details"} leftColumn={<Routes/>} rightColumn={<Passengers/>}/>
                <ColoredBox color={colors.secondDarkBackground}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: {xs: "column", sm: "row"},
                        alignItems: "center",
                        justifyContent: "center",
                        rowGap: 1,
                        columnGap: 4,
                        paddingY: {xs: 3.5, sm: 7}
                    }}>
                        <Box sx={{color: colors.white}}>
                            <Box sx={{
                                fontSize: "3.8rem",
                                fontWeight: 700,
                                lineHeight: 1,
                                textAlign: {xs: "center", sm: "start"}
                            }}>
                                {totals?.price}
                            </Box>
                            <Box sx={{
                                fontSize: "1.4rem",
                                fontWeight: 400,
                            }}>
                                Total price for all passengers
                            </Box>
                        </Box>
                        <Box>
                            {passedTime && <CounterButton passedTime={passedTime} clickHandler={submitBooking}/>}
                        </Box>
                    </Box>
                </ColoredBox>
            </>}
            {!bookingValid && <Box sx={headerStyle}>Booking expired</Box>}
        </>
    )
}

type CounterButtonProps = {
    passedTime: number,
    clickHandler: ClickEventHandler
}
const CounterButton: React.FC<CounterButtonProps> = props => {
    const {passedTime, clickHandler} = props;
    const [time, setTime] = useState<number>(MAX_BOOKING_TIME - passedTime);

    //reload when booking time expired
    if (time <= 0) window.location.reload();

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const displayTime = () => new Date((time) * 1000).toISOString().slice(11, 19);

    return (
        <Button sx={{minWidth: 230}} onClick={clickHandler}>Book | {displayTime()}</Button>
    );
}

export default Book;