import React from "react";
import {Box, FormControl, FormHelperText} from "@mui/material";
import Rate from "./index";

type Props = {
    errorText?: string
}
const ReviewRate: React.FC<Props> = (props) => {
    const {errorText = ""} = props;

    return (
        <Box sx={{
            paddingY: {xs: 3, md: 5},
            textAlign: "center"
        }}>
            <Box sx={{
                marginX: {xs: 2, sm: 8}
            }}>
                <Rate/>
            </Box>

            {errorText &&
                <FormControl fullWidth error>
                    <FormHelperText>
                        {errorText}
                    </FormHelperText>
                </FormControl>
            }
        </Box>
    );
}

export default ReviewRate;