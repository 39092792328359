import {SvgIcon, SvgIconProps} from "@mui/material";
import React from "react";

const RadioIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 23, height: 23, fill: "none"}} viewBox="0 0 23 23">
        <rect x="0.75" y="0.75" width="21.5" height="21.5" rx="4.25" fill="white" stroke={props.htmlColor}
              strokeWidth="1.5"/>
    </SvgIcon>

const RadioCheckedIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 23, height: 23, fill: "none"}} viewBox="0 0 23 23">
        <rect x="0.75" y="0.75" width="21.5" height="21.5" rx="4.25" fill="white" stroke={props.htmlColor}
              strokeWidth="1.5"/>
        <rect x="7" y="7" width="9" height="9" rx="2" fill={props.htmlColor}/>
    </SvgIcon>

const CheckboxIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 23, height: 23, fill: "none"}} viewBox="0 0 23 23">
        <rect x="0.75" y="0.75" width="21.5" height="21.5" rx="4.25" fill="white" stroke={props.htmlColor}
              strokeWidth="1.5"/>
    </SvgIcon>

const CheckboxCheckedIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 23, height: 23, fill: "none"}} viewBox="0 0 23 23">
        <rect x="0.75" y="0.75" width="21.5" height="21.5" rx="4.25" fill="white" stroke={props.htmlColor}
              strokeWidth="1.5"/>
        <path d="M5 10.5L10 15.5L18 7.5" stroke={props.htmlColor} strokeWidth="2"/>
    </SvgIcon>

// const AlertInfoIcon = (props: SvgIconProps) =>
//     <SvgIcon {...props} sx={{width: 24, height: 24, fill: "none"}} viewBox="0 0 24 24">
//         <path fillRule="evenodd" clipRule="evenodd"
//               d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z"
//               fill="#231F20"/>
//         <mask id="mask0_164_2115" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="2" y="2" width="20"
//               height="20">
//             <path fillRule="evenodd" clipRule="evenodd"
//                   d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z"
//                   fill="white"/>
//         </mask>
//         <g mask="url(#mask0_164_2115)">
//             <rect width="24" height="24" fill="#E31F3D"/>
//         </g>
//     </SvgIcon>

const AddButtonIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 25, height: 25, fill: "none"}} viewBox="0 0 25 25">
        <rect x="1" y="1" width="23" height="23" rx="8" stroke="#E31F3D" strokeWidth="2"/>
        <line x1="6.5791" y1="12.1579" x2="18.4212" y2="12.1579" stroke="#E31F3D" strokeWidth="2"/>
        <line x1="12.8423" y1="6.57892" x2="12.8423" y2="18.421" stroke="#E31F3D" strokeWidth="2"/>
    </SvgIcon>

const SelectIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 11, height: 6, fill: "none"}} viewBox="0 0 11 6">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L5.25 4.18934L9.21967 0.21967C9.51256 -0.0732233 9.98744 -0.0732233 10.2803 0.21967C10.5732 0.512563 10.5732 0.987437 10.2803 1.28033L5.78033 5.78033C5.48744 6.07322 5.01256 6.07322 4.71967 5.78033L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z"
              fill="#DF1F3D"/>
    </SvgIcon>

const CircleArrowUpIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 38, height: 38, fill: "none"}} viewBox="0 0 38 38">
        <path
            d="M19 0C8.52402 0 0 8.52328 0 19C0 29.4767 8.52402 38 19 38C29.4767 38 38 29.4767 38 19C38 8.52328 29.476 0 19 0ZM19 35.2264C10.0529 35.2264 2.77355 27.9471 2.77355 19C2.77355 10.0529 10.0529 2.77355 19 2.77355C27.9471 2.77355 35.2264 10.0529 35.2264 18.9993C35.2264 27.9471 27.9471 35.2264 19 35.2264Z"
            fill="white"/>
        <path d="M11 22L19 14L27 22" stroke="#E31F3D" strokeWidth="3"/>

    </SvgIcon>

const CircleArrowDownIcon = (props: SvgIconProps) =>
    <SvgIcon {...props} sx={{width: 38, height: 38, fill: "none"}} viewBox="0 0 38 38">
        <path
            d="M19 38C29.476 38 38 29.4767 38 19C38 8.52328 29.476 -7.45194e-07 19 -1.66103e-06C8.52328 -2.57694e-06 2.57694e-06 8.52328 1.66103e-06 19C7.45129e-07 29.4767 8.52402 38 19 38ZM19 2.77355C27.9471 2.77355 35.2264 10.0529 35.2264 19C35.2264 27.9471 27.9471 35.2264 19 35.2264C10.0529 35.2264 2.77356 27.9471 2.77356 19.0007C2.77356 10.0529 10.0529 2.77355 19 2.77355Z"
            fill="white"/>
        <path d="M27 16L19 24L11 16" stroke="#E31F3D" strokeWidth="3"/>
    </SvgIcon>

export {
    RadioIcon,
    RadioCheckedIcon,
    CheckboxIcon,
    CheckboxCheckedIcon,
    //AlertInfoIcon,
    AddButtonIcon,
    SelectIcon,
    CircleArrowUpIcon,
    CircleArrowDownIcon
};