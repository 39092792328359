import React, {ReactElement} from "react";
import {Box} from "@mui/material";
import {colors} from "../../../theme";

type Props = {
    step: number;
}

type StepDetails = {
    backgroundImage: string;
    foreground: string | ReactElement;
    text: string,
    selected: boolean
}

const Steps: React.FC<Props> = (props) => {
    const {step} = props;
    const maxStep: number = 4;
    const text: Array<string> = [
        "Selection", "Reservation", "Payment", "Ticket Confirmation"
    ]

    let steps: Array<StepDetails> = [];
    for (let i: number = 1; i <= maxStep; i++) {
        steps.push({
            backgroundImage: i === step ? "/images/ellipse-red.svg" : "/images/ellipse-gray.svg",
            foreground: i >= step
                ? i.toString()
                : <Box src={"/images/checked.svg"} component={"img"} alt={`step ${i}`}/>,
            text: text[i - 1],
            selected: i === step
        });
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: {xs: "center", md: "left"},
        }}>
            {steps.map((step, i) =>
                <React.Fragment key={i}>
                    <StepItem {...step} />
                    {i < steps.length - 1 && <Box sx={{
                        width: 40,
                        height: 1,
                        marginTop: "20px",
                        borderTop: "2px dotted #9BA3BE"
                    }}></Box>}
                </React.Fragment>
            )}
        </Box>
    );
}

const StepItem: React.FC<StepDetails> = (props) => {
    const {backgroundImage, foreground, text, selected} = props;

    return (
        <Box sx={{
            width: 72
        }}>
            <Box sx={{
                backgroundImage: `url(${backgroundImage})`,
                width: 40,
                height: 40,
                textAlign: "center",
                fontSize: "2.4rem",
                fontWeight: "bold",
                color: `${selected ? colors.white : "#11172B"}`,
                lineHeight: "40px",
                marginX: 2
            }}>
                {foreground}
            </Box>
            <Box sx={{
                textAlign: "center",
                color: "#9BA3BE",
                fontSize: "1.2rem",
                paddingTop: "10px"
            }}>
                {text}
            </Box>
        </Box>
    )
}

export default Steps;