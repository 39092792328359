import {FC, memo} from "react";
import ModalWindow from "../Modal";
import {Box, Typography} from "@mui/material";
import {cdn} from "../../util";
import {useAppDispatch, useAppSelector} from "../../store";
import {openCardPhoneModal} from "../../features/modal/modalSlice";

const CardPhoneModal: FC = () => {
    const dispatch = useAppDispatch();
    const open: boolean = useAppSelector(state => state.modal.cardPhoneModal);

    const handleClose = () => {
        dispatch(openCardPhoneModal(false))
    }

    return (
        <ModalWindow
            title={"Bank’s Phone Number"}
            open={open}
            onClose={handleClose}
        >
            <Typography
                sx={{
                    paddingTop: 5,
                    fontWeight: 500
                }}
            >
                Bank’s phone number is located on the backside of credit/debit card.
            </Typography>
            <Box
                sx={{
                    textAlign: "center",
                    marginY: 3
                }}
            >
                <Box
                    component={"img"}
                    src={cdn("/images/card-bank-phone.svg")}
                    sx={{maxWidth: "100%"}}
                    alt={"credit card bank phone"}
                />
            </Box>
        </ModalWindow>
    )
}

export default memo(CardPhoneModal);