import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStatePlantATree} from "../../types";
import {plantATreeSliceDefaultValue} from "../../defaultValues";
import {getLocalPlantATreeAmount, updateLocalPlantATreeAmount} from "./thunks/plantATreeAmount";
import {INITIAL_PLANTATREE_AMOUNT} from "../../constants";

const plantATreeAdapter = createEntityAdapter();

const initialState: IStatePlantATree = plantATreeAdapter.getInitialState(plantATreeSliceDefaultValue);

const plantATreeSlice = createSlice({
    name: "plantATree",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLocalPlantATreeAmount.fulfilled, (state: IStatePlantATree, action: PayloadAction<number | null>) => {
                state.amount = action.payload == null ? INITIAL_PLANTATREE_AMOUNT : action.payload;
                state.confirmed = action.payload != null;
            })
            .addCase(updateLocalPlantATreeAmount.fulfilled, (state: IStatePlantATree, action: PayloadAction<number>) => {
                state.amount = action.payload;
                state.confirmed = true;
            })
    },
});

export default plantATreeSlice.reducer;