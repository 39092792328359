import React from "react";
import {Box, Grid} from "@mui/material";
import {IFlight} from "../../../types";
import Social from "../../Social";
import {cdn} from "../../../util";
import {colors} from "../../../theme";
import FlightDetails from "./FlightDetails";

type Props = {
    flight: IFlight
}
const Flight: React.FC<Props> = props => {
    const {flight} = props;

    return (
        <Box sx={{
            paddingBottom: 2
        }}>
            <Grid container columnSpacing={{xs: 0.5, sm: 0}}>
                <Grid item xs={3} sm={4} sx={{
                    textAlign: {xs: "start", sm: "center"},
                }}>
                    <Box component={"img"} src={flight.airlineLogo} alt={flight.airlineName}/>
                    <Box component={"p"} sx={{
                        fontSize: {xs: "1.2rem", sm: "1.4rem"},
                        marginTop: 1,
                        marginBottom: 0
                    }}>
                        {flight.airlineName}
                    </Box>
                </Grid>
                <Grid item xs sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: {xs: "flex-start", sm: "space-between"}
                }}>
                    <FlightDetails
                        date={flight.startDate}
                        locationCode={flight.startLocationCode}
                        locationName={flight.startLocationName}
                        icon={cdn("/images/departure.png")}
                        detailsTypeText={"Departure"}
                    />
                </Grid>
                <Grid item xs sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: {xs: "flex-start", sm: "space-between"}
                }}>
                    <FlightDetails
                        date={flight.endDate}
                        locationCode={flight.endLocationCode}
                        locationName={flight.endLocationName}
                        icon={cdn("/images/arrival.png")}
                        detailsTypeText={"Arrival"}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={{xs: 0.5, sm: 0}} sx={{
                marginTop: {xs: 2, sm: 4},
                marginBottom: {xs: 1, sm: 2}
            }}>
                <Grid item xs={3} sm={4}>
                    <Social
                        icon={cdn("/images/flight.png")}
                        iconWidth={"15px"}
                        textSx={{
                            color: colors.secondDarkBackground,
                            fontSize: {xs: "1.2rem", sm: "1.4rem"},
                            fontWeight: 400
                        }}
                        sx={{justifyContent: {xs: "left", sm: "center"}}}
                    >
                        Flight {flight.flightName}
                    </Social>
                </Grid>
                <Grid item xs>
                    <Social
                        icon={cdn("/images/time.png")}
                        iconWidth={"15px"}
                        textSx={{
                            color: colors.secondDarkBackground,
                            fontSize: {xs: "1.2rem", sm: "1.4rem"},
                            fontWeight: 400
                        }}
                    >
                        {flight.airTime}
                    </Social>
                </Grid>
                <Grid item xs>
                    <Social
                        icon={cdn("/images/flight-class.png")}
                        iconWidth={"12px"}
                        textSx={{
                            color: colors.secondDarkBackground,
                            fontSize: {xs: "1.2rem", sm: "1.4rem"},
                            fontWeight: 400
                        }}
                    >
                        {flight.flightClass}
                    </Social>
                </Grid>
            </Grid>
            {flight.stopOver && <Box sx={{
                paddingY: 0.5,
                backgroundColor: "#F3F8FD",
                marginX: {xs: -1, sm: -3, md: -1, lg: -3}
            }}>
                <Box>
                    <Social
                        icon={cdn("/images/time.png")}
                        iconWidth={"12px"}
                        textSx={{fontSize: {xs: "1.4rem", sm: "1.6rem"}, fontWeight: 500}}
                        sx={{justifyContent: "center"}}
                    >
                        {flight.stopOver} Layover in {flight.endLocationName}
                    </Social>
                </Box>

                {flight.warningStopOverLocation && <Box sx={{
                    paddingY: 0.5,
                }}>
                    <Social
                        icon={cdn("/images/time.png")}
                        iconWidth={"12px"}
                        textSx={{fontSize: {xs: "1.4rem", sm: "1.6rem"}, fontWeight: 700, color: colors.red}}
                        sx={{justifyContent: "center"}}
                    >
                        {flight.warningStopOverLocation}
                    </Social>
                </Box>}

            </Box>}
        </Box>
    );
}

export default React.memo(Flight);