import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {ChangeEvent, ClickEvent, Gender, IError, IPassenger, IProgramNumber} from "../../../types";
import moment from "moment";
import {cdn} from "../../../util";
import AlertText from "../../AlertText";
import {colors} from "../../../theme";
import AddButton from "../../AddButton";
import ClearIcon from "@mui/icons-material/Clear";
import {NonEditFormControl, SelectFormControl} from "../utils";
import PassengerDateOfBirth from "./PassengerDateOfBirth";
import {PassengerFunctionsContext, PassengerProps} from "./index";
import {passengerPreferences} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../store";
import useErrors from "../../../hooks/useErrors";
import {addPassengerErrors} from "../../../features/passengers/passengersSlice";
import {updateLocalPassenger} from "../../../features/passengers/thunks/passenger";
import {validatePassengers} from "../validation";

const PaymentPassenger: React.FC<PassengerProps> = props => {
    const {passenger, passengerType} = props;
    const dispatch = useAppDispatch();
    const passengersErrors: Array<Array<IError>> = useAppSelector(state => state.passengers.errors);
    const errors = passengersErrors[passenger.id];
    const {getFieldError} = useErrors(errors);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [showRedressNumber, setShowRedressNumber] = useState<boolean>(false);
    const [showFlyingPreferences, setShowFlyingPreferences] = useState<boolean>(false);
    const [programNumberCount, setProgramNumberCount] = useState<number>(1);

    const {updatePassengerData, handleInputChange} = useContext(PassengerFunctionsContext);

    useEffect(() => {
        setShowRedressNumber(!!(passenger.redressNumber));
        setShowFlyingPreferences(!!(passenger.programNumbers?.length) || !!(passenger.mealPreference) || !!(passenger.seatPreference) || !!(passenger.specialAssistance));
        setProgramNumberCount(passenger.programNumbers?.length || 1);
    }, [passenger]);

    const handleSelectChange = (e: SelectChangeEvent): void => {
        updatePassengerData({...passenger, [e.target.name]: e.target.value});
    };

    const handleShowRedressNumber = (checked: boolean) => {
        setShowRedressNumber(checked);
        if (!checked) {
            updatePassengerData({...passenger, redressNumber: ""});
        }
    }

    const handleShowFlyingPreferences = (checked: boolean) => {
        setShowFlyingPreferences(checked);
        if (!checked) {
            updatePassengerData({
                ...passenger,
                programNumbers: [],
                seatPreference: "",
                mealPreference: "",
                specialAssistance: ""
            });
        }
    }

    const handleProgramNumbersChange = (objectItem: "frequentFlyer" | "number", index: number, value: string): void => {
        let programNumbers = passenger.programNumbers ? JSON.parse(JSON.stringify(passenger.programNumbers)) : [];
        if (!programNumbers[index]) {
            programNumbers[index] = {frequentFlyer: "", number: ""} as IProgramNumber
        }
        programNumbers[index][objectItem] = value;

        updatePassengerData({...passenger, programNumbers: programNumbers});
    }

    const handleAddProgramNumber = (e: ClickEvent): void => {
        setProgramNumberCount(prev => prev + 1);
    };

    const handleRemoveProgramNumber = (index: number): void => {
        let programNumbers = passenger.programNumbers ? JSON.parse(JSON.stringify(passenger.programNumbers)) : [];
        if (programNumbers.length) {
            programNumbers.splice(index, 1);
            updatePassengerData({...passenger, programNumbers: programNumbers});
        }
        setProgramNumberCount(prev => prev > 1 ? prev - 1 : prev);
    };

    const updatePassenger = (passenger: IPassenger) => {
        const errors = validatePassengers([passenger]);
        dispatch(addPassengerErrors(errors));
        if (errors.length) {
            return;
        }

        dispatch(updateLocalPassenger(passenger));
        setEditMode(false);
    }

    return (
        <>
            <AlertText
                text="The passenger names must match the travel documents. Name changes are not allowed."
                sx={{
                    marginBottom: 2,
                    backgroundColor: colors.liteGrayBackground,
                    paddingX: 3,
                    paddingY: 2
                }}
            />

            <Box
                sx={{
                    paddingX: {xs: 1, md: 3}
                }}
            >
                <Grid container id={`passenger-${passenger.id}`} rowSpacing={4}>
                    {editMode ?
                        <>
                            <Grid item xs={12} sm={3}>
                                <FormControl>
                                    <FormLabel required>Gender</FormLabel>
                                    <RadioGroup row value={passenger.gender} name="gender" onChange={handleInputChange}>
                                        <FormControlLabel value={Gender.MALE} control={<Radio/>} label="Male"/>
                                        <FormControlLabel value={Gender.FEMALE} control={<Radio/>} label="Female"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                name="firstName"
                                                value={passenger.firstName}
                                                onChange={handleInputChange}
                                                error={!!getFieldError("firstName")}
                                                helperText={getFieldError("firstName")}
                                                label={"First name"}
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                name="lastName"
                                                value={passenger.lastName}
                                                onChange={handleInputChange}
                                                error={!!getFieldError("lastName")}
                                                helperText={getFieldError("lastName")}
                                                label={"Last name"}
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                name="middleName"
                                                value={passenger.middleName}
                                                onChange={handleInputChange}
                                                label={"Middle name"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <PassengerDateOfBirth passenger={passenger} passengerType={passengerType}/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}
                                  sx={{
                                      textAlign: {xs: "center", sm: "end"},
                                      display: {xs: "flex", sm: "unset"},
                                      flexDirection: {xs: "column", sm: "row"},
                                      alignItems: "center"
                                  }}
                            >
                                <Button
                                    variant={"cancel"}
                                    onClick={() => setEditMode(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => updatePassenger(passenger)}
                                >
                                    Confirm changes
                                </Button>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={9} sm={3} sx={{order: 1}}>
                                <NonEditFormControl
                                    label={"Gender"}
                                    value={passenger.gender === Gender.MALE ? "Male" : "Female"}
                                />
                            </Grid>
                            <Grid item xs={9} sm={7} sx={{order: {xs: 3, sm: 2}}}>
                                <Grid container columnSpacing={1} rowSpacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <NonEditFormControl
                                            label={"First name"}
                                            value={passenger.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NonEditFormControl
                                            label={"Last name"}
                                            value={passenger.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NonEditFormControl
                                            label={"Middle name"}
                                            value={passenger.middleName || ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NonEditFormControl
                                            label={"Date of birth"}
                                            value={passenger.dateOfBirth ? moment(passenger.dateOfBirth).format("MMM D, Y") : ""}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sm={2} sx={{order: {xs: 2, sm: 3}}}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setEditMode(true)}
                                >
                                    <Typography
                                        component={"span"}
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "1.8rem",
                                            marginRight: "5px"
                                        }}
                                    >
                                        Edit
                                    </Typography>
                                    <Box component={"img"} src={cdn("/images/edit.svg")}/>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>

            <Box
                sx={{
                    backgroundColor: colors.grayBackground,
                    marginTop: 4,
                    paddingX: 3,
                    paddingY: 2
                }}
            >
                <Box>
                    <FormControl>
                        <FormControlLabel
                            checked={showRedressNumber}
                            control={
                                <Checkbox
                                    onChange={(e: ChangeEvent, checked: boolean) => handleShowRedressNumber(checked)}
                                />
                            }
                            label="Redress number"
                            componentsProps={{
                                typography: {
                                    sx: {
                                        fontSize: "2.1rem",
                                        fontWeight: "bold",
                                        marginLeft: 0.5
                                    }
                                }
                            }}
                        />
                    </FormControl>

                    {showRedressNumber &&
                        <Grid
                            container
                            sx={{
                                marginBottom: 3,
                                marginTop: 1
                            }}
                        >
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        name="redressNumber"
                                        value={passenger.redressNumber}
                                        onChange={handleInputChange}
                                        error={!!getFieldError("redressNumber")}
                                        helperText={getFieldError("redressNumber")}
                                        label={"Enter number"}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </Box>

                <Box>
                    <FormControl>
                        <FormControlLabel
                            checked={showFlyingPreferences}
                            control={
                                <Checkbox
                                    onChange={(e: ChangeEvent, checked: boolean) => handleShowFlyingPreferences(checked)}
                                />
                            }
                            label="Frequent flyer and seat/meal preferences"
                            componentsProps={{
                                typography: {
                                    sx: {
                                        fontSize: "2.1rem",
                                        fontWeight: "bold",
                                        marginLeft: 0.5
                                    }
                                }
                            }}
                        />
                    </FormControl>

                    {showFlyingPreferences && <>
                        <Grid
                            container
                            columnSpacing={1}
                            rowSpacing={{xs: 2, md: 1}}
                            sx={{
                                marginBottom: 3,
                                paddingTop: {xs: 2, md: 1}
                            }}
                        >
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormControl fullWidth>
                                    <SelectFormControl
                                        label={"Select Seat Preference"}
                                        options={passengerPreferences.seatPreference}
                                        name={"seatPreference"}
                                        value={passenger.seatPreference || ""}
                                        onChange={handleSelectChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormControl fullWidth>
                                    <SelectFormControl
                                        label={"Select Meal Preference"}
                                        options={passengerPreferences.mealPreference}
                                        name={"mealPreference"}
                                        value={passenger.mealPreference || ""}
                                        onChange={handleSelectChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormControl fullWidth>
                                    <SelectFormControl
                                        label={"Select Special Assistance"}
                                        options={passengerPreferences.specialAssistance}
                                        name={"specialAssistance"}
                                        value={passenger.specialAssistance || ""}
                                        onChange={handleSelectChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {[...Array(programNumberCount)].map((x, i) =>
                            <Grid
                                key={i}
                                container
                                columnSpacing={1}
                                rowSpacing={{xs: 2, md: 1}}
                                sx={{
                                    paddingBottom: 3
                                }}
                            >
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <SelectFormControl
                                            label={"Select Frequent Flyer"}
                                            options={passengerPreferences.frequentFlyer}
                                            name={`programNumbers[${i}][frequentFlyer]`}
                                            value={(passenger.programNumbers && passenger.programNumbers[i]?.frequentFlyer) || ""}
                                            onChange={(e: SelectChangeEvent) => handleProgramNumbersChange("frequentFlyer", i, e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            name={`programNumbers[${i}][number]`}
                                            value={(passenger.programNumbers && passenger.programNumbers[i]?.number) || ""}
                                            onChange={(e: ChangeEvent) => handleProgramNumbersChange("number", i, e.target.value)}
                                            error={!!getFieldError("programNumber")}
                                            helperText={getFieldError("programNumber")}
                                            label={"Program Number"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    {i + 1 === programNumberCount ?
                                        <AddButton
                                            text={`Add program number`}
                                            onClick={handleAddProgramNumber}
                                        />
                                        :
                                        <ClearIcon
                                            onClick={(e: ClickEvent) => handleRemoveProgramNumber(i)}
                                            sx={{cursor: "pointer"}}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        )}
                    </>}
                </Box>
            </Box>
        </>
    );
}

export default PaymentPassenger;