import {useAppDispatch, useAppSelector} from "../../store";
import {IExpertRemuneration, IOfferTotal, IStatePlantATree, IStateTravelProtection} from "../../types";
import {useEffect} from "react";
import {updateTotal} from "../../features/flight-offer/flightOfferSlice";
import {flightOfferSliceDefaultValue} from "../../defaultValues";

const useTotal = () => {
    const dispatch = useAppDispatch();
    const total: IOfferTotal = useAppSelector(state => state.flightOffer.total) || flightOfferSliceDefaultValue.total;
    const plantATree: IStatePlantATree = useAppSelector(state => state.plantATree);
    const travelProtection: IStateTravelProtection = useAppSelector(state => state.travelProtection);
    const expertRemuneration: IExpertRemuneration = useAppSelector(state => state.expert.remuneration);
    const totalPrice = //total.priceDetails ? (
        total.priceDetails.price +
        total.priceDetails.travelProtectionPrice +
        total.priceDetails.plantATreePrice +
        total.priceDetails.expertRemunerationPrice
    //) : 0;

    useEffect(() => {
        let newTotal: IOfferTotal = {...total};
        newTotal.priceDetails = {...total.priceDetails};
        newTotal.priceDetails.travelProtectionPrice = travelProtection.state ? travelProtection.sum : 0;
        newTotal.priceDetails.plantATreePrice = plantATree.confirmed ? plantATree.amount : 0;
        newTotal.priceDetails.expertRemunerationPrice = expertRemuneration.confirmed ? expertRemuneration.amount : 0;
        dispatch(updateTotal(newTotal));
    }, [travelProtection, plantATree, expertRemuneration, dispatch]);

    return {totalPrice, total};
}

export default useTotal;