import React, {useEffect} from "react";
import Passenger from "./Passenger";
import Block, {BlockSubTitle} from "../Block";
import {Box} from "@mui/material";
import AlertText from "../AlertText";
import AddButton from "../AddButton";
import {ClickEvent, IOfferTotal, IPassenger, PassengerView} from "../../types";
import {useAppDispatch, useAppSelector} from "../../store";
import {getAllPassengers} from "../../features/passengers/passengersSlice";
import {getPassengerType, PassengerTitle} from "./utils";
import {
    addLocalPassenger,
    deleteLocalPassenger,
    fetchPaymentPassengers
} from "../../features/passengers/thunks/passenger";

const Passengers: React.FC = props => {
    const dispatch = useAppDispatch();
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const totals: IOfferTotal = useAppSelector(state => state.flightOffer.total);
    const totalPassengers: number = totals.adults + totals.children + totals.newborns;
    const passengersList: Array<IPassenger> = useAppSelector(getAllPassengers);

    const isAllowedToAddPassenger = () => passengersList.length < totalPassengers;

    const handleAddPassenger = (e: ClickEvent): void => {
        offerId && isAllowedToAddPassenger() && dispatch(addLocalPassenger());
    };

    const handleRemovePassenger = (passenger: IPassenger) => {
        offerId && dispatch(deleteLocalPassenger(passenger))
    }

    useEffect(() => {
        offerId && dispatch(fetchPaymentPassengers());
    }, [dispatch, offerId]);

    return (
        <Block title={"Passenger details"} topRounded bottomRounded>
            {
                passengersList.map((item, key) => {
                    const passengerType = getPassengerType(totals, key);

                    return (
                        <React.Fragment key={item.id}>
                            <BlockSubTitle
                                title={
                                    <PassengerTitle
                                        title={`Passenger #${key + 1} ${passengerType.title}`}
                                        showCloseIcon={passengersList.length > 1}
                                        onClickClose={() => handleRemovePassenger(item)}
                                    />
                                }
                                extend
                            />
                            <Box sx={{paddingBottom: 4, paddingTop: 2}}>
                                <Passenger
                                    passenger={item}
                                    passengerType={passengerType.type}
                                    passengerView={PassengerView.BookingPassenger}
                                />
                            </Box>
                        </React.Fragment>
                    )
                })
            }
            {isAllowedToAddPassenger() && <Box sx={{paddingBottom: 3}}>
                <AddButton
                    text={`Add passenger (max ${totalPassengers - passengersList.length})`}
                    onClick={handleAddPassenger}
                />
            </Box>}
            <Box sx={{paddingTop: 2, paddingBottom: 4}}>
                <AlertText text="The quoted prices are not guaranteed until the tickets are issued."/>
            </Box>
        </Block>
    );
}

export default Passengers;