import React from "react";
import RouteSummary from "./RouteSummary";
import Box from "@mui/material/Box";
import MobilePriceSummary from "./PriceSummary/MobilePriceSummary";
import {useAppSelector} from "../../store";

const Mobile: React.FC = props => {
    const isSummaryOpen = useAppSelector(state => state.purchaseSummary.isOpen);

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                marginLeft: {xs: -2, sm: -3},
                zIndex: 999,
                maxHeight: "100vh",
                overflowY: "auto"
            }}
        >
            {isSummaryOpen && <RouteSummary/>}
            {isSummaryOpen && <Box sx={{height: "1px", backgroundColor: "#E8EDF3"}}></Box>}
            <MobilePriceSummary/>
        </Box>
    )
}

export default Mobile;