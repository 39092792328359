import {FC} from "react";
import {Theme, useMediaQuery} from "@mui/material";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

const PurchaseSummary: FC = props => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        (isMobile ? <Mobile/> : <Desktop/>)
    );
}

export default PurchaseSummary;