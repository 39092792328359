import React, {createContext} from "react";
import {ChangeEvent, IPassenger, PassengerType, PassengerView} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../store";
import BookingPassenger from "./BookingPassenger";
import PaymentPassenger from "./PaymentPassenger";
import {updateLocalPassenger} from "../../../features/passengers/thunks/passenger";

export type PassengerProps = {
    passenger: IPassenger,
    passengerType: PassengerType
}

type Props = PassengerProps & {
    passengerView: PassengerView
}

const Passenger: React.FC<Props> = props => {
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const {passenger, passengerType, passengerView} = props;
    const dispatch = useAppDispatch();

    const handleInputChange = (e: ChangeEvent): void => {
        updatePassengerData({...passenger, [e.target.name]: e.target.value});
    };

    const handleDatePickerChange = (value: string): void => {
        updatePassengerData({...passenger, dateOfBirth: value});
    }

    const updatePassengerData = (passenger: IPassenger): void => {
        offerId && dispatch(updateLocalPassenger(passenger));
    }

    return (
        <PassengerFunctionsContext.Provider value={{
            updatePassengerData: updatePassengerData,
            handleInputChange: handleInputChange,
            handleDatePickerChange: handleDatePickerChange
        }}>
            {
                passengerView === PassengerView.BookingPassenger ?
                    <BookingPassenger
                        passenger={passenger}
                        passengerType={passengerType}
                    /> :
                    <PaymentPassenger
                        passenger={passenger}
                        passengerType={passengerType}
                    />
            }
        </PassengerFunctionsContext.Provider>
    )
}

export default Passenger;

export const PassengerFunctionsContext = createContext({
    updatePassengerData: (passenger: IPassenger): void => {
    },
    handleInputChange: (e: ChangeEvent): void => {
    },
    handleDatePickerChange: (value: string): void => {
    }
});