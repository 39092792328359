import {IError, IReview} from "../../types";
import {isValidEmail, isValidNonEmptySting} from "../../validation";

export const validateReviewForm = (review: IReview) => {
    let errors: Array<IError> = [];

    if (review.rate < 0 || review.rate > 5) {
        errors.push({name: 'rate', message: "Wrong rate value"});
    }

    if (!isValidNonEmptySting(review.name)) {
        errors.push({name: 'name', message: "Name is empty"});
    }

    if (!isValidNonEmptySting(review.email)) {
        errors.push({name: 'email', message: "Email is empty"});
    } else if (!isValidEmail(review.email)) {
        errors.push({name: "email", message: "Wrong email format"})
    }

    if (!isValidNonEmptySting(review.fromLocation)) {
        errors.push({name: 'fromLocation', message: "From location is empty"});
    }

    if (!isValidNonEmptySting(review.toLocation)) {
        errors.push({name: 'toLocation', message: "To location is empty"});
    }

    if (!isValidNonEmptySting(review.message)) {
        errors.push({name: 'message', message: "Message is empty"});
    }

    return errors;
}