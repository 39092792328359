import {IStateFlightOffer} from "../../../types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_PLANTATREE_AMOUNT} from "../../../constants";

const setPlantATreeAmountToStorage = (offerId: string, amount: number): void => {
    sessionStorage.setItem(STORAGE_PLANTATREE_AMOUNT(offerId), amount.toString());
}

export const getLocalPlantATreeAmount = createAsyncThunk<number | null, void, {
    state: { flightOffer: IStateFlightOffer }
}>('plantatree/getLocalPlantATreeAmount', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        let localAmount: string | null = sessionStorage.getItem(STORAGE_PLANTATREE_AMOUNT(flightOffer.offerId));
        if (localAmount != null) {
            const intLocalAmount = parseInt(localAmount);
            if (!Number.isNaN(intLocalAmount)) {
                return intLocalAmount;
            }
        }
    }

    return null;
});

export const updateLocalPlantATreeAmount = createAsyncThunk<number, number, {
    state: { flightOffer: IStateFlightOffer }
}>('plantatree/updateLocalPlantATreeAmount', async (amount: number, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        setPlantATreeAmountToStorage(flightOffer.offerId, amount);
    }

    return amount;
});