import React from "react";
import {Box} from "@mui/material";
import Social from "../../Social";

type Props = {
    date: string,
    locationName: string,
    locationCode: string,
    icon: string,
    detailsTypeText: string
}
const FlightDetails: React.FC<Props> = props => {
    const {date, locationCode, locationName, icon, detailsTypeText} = props;

    return (
        <>
            <Social
                icon={icon}
                iconWidth={"19px"}
                textSx={{color: "#9BA3BE", fontSize: {xs: "1.4rem", sm: "1.6rem"}, fontWeight: 500}}
            >
                {detailsTypeText}
            </Social>
            <Box component={"p"} sx={{
                fontWeight: 700,
                fontSize: {sm: "1.8rem"},
                margin: 0
            }}>
                {date}
            </Box>
            <Box component={"p"} sx={{
                fontWeight: 400,
                fontSize: {xs: "1.2rem", sm: "1.4rem"},
                margin: 0
            }}>
                <Box component={"span"} sx={{display: {xs: "block", sm: "inline"}}}>{locationName}</Box>
                <Box component={"span"} sx={{display: {xs: "block", sm: "inline"}}}> ({locationCode})</Box>
            </Box>
        </>
    );
}

export default React.memo(FlightDetails);