import React, {memo, useDeferredValue, useEffect, useRef, useState} from "react";
import {MuiTelInput} from "mui-tel-input";
import {FormHelperTextProps, InputProps, SxProps} from "@mui/material";

type Props = {
    value: string;
    onChange: (value: string) => void;
    error: string;
    label: string;
    name: string;
    required?: boolean;
    InputProps?: InputProps;
    FormHelperTextProps?: FormHelperTextProps
    sx?: SxProps;
}
const PhoneField: React.FC<Props> = (props) => {
    const {
        value,
        onChange,
        error,
        label,
        name,
        required = false,
        sx = {},
        InputProps = {},
        FormHelperTextProps = {}
    } = props;
    const [phone, setPhone] = useState<string>(value);
    const deferredPhone = useDeferredValue<string>(phone);
    const ref = useRef<boolean>(true);

    useEffect(() => {
        if (!ref.current) {
            onChange(deferredPhone)
        }
    }, [deferredPhone]);

    useEffect(() => {
        setPhone(value)
    }, [value]);

    return (
        <MuiTelInput
            variant="outlined"
            name={name}
            value={phone}
            onChange={(value: string) => {
                ref.current = false;
                setPhone(value);
            }}
            // flagSize={"medium"}
            label={label}
            required={required}
            defaultCountry={"US"}
            error={!!error}
            helperText={error}
            sx={sx}
            InputProps={InputProps}
            FormHelperTextProps={FormHelperTextProps}
        />
    );
}

export default memo(PhoneField);