import React from "react";

import {Button} from "@mui/material";
import {AddButtonIcon} from "../Icons";
import {ClickEventHandler} from "../../types";

type Props = {
    text: string,
    onClick: ClickEventHandler
};

const AddButton: React.FC<Props> = props => {
    const {text, onClick} = props;

    return (
        <Button
            variant="add-button"
            startIcon={<AddButtonIcon/>}
            onClick={onClick}
            sx={{
                backgroundColor: "transparent"
            }}
        >
            {text}
        </Button>
    );
}

export default AddButton;