import {Autocomplete, Box, FormControl, Grid, TextField} from "@mui/material";
import React, {SyntheticEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {countries} from "../../constants";
import {ChangeEvent, CountryType, IBillingAddress, StateType} from "../../types";
import {getLocalBillingDetails, updateLocalBillingDetails} from "../../features/billingDetails/thunks/billingDetails";
import PhoneField from "../PhoneField";
import useErrors from "../../hooks/useErrors";
import {getStatesByCountry} from "../../util";

const BillingForm: React.FC = props => {
    const dispatch = useAppDispatch();
    const offerId: string = useAppSelector(state => state.flightOffer.offerId);
    const billingDetails: IBillingAddress = useAppSelector(state => state.billingDetails.billingDetails);
    const errors = useAppSelector(state => state.billingDetails.errors);
    const {getFieldError} = useErrors(errors);

    const selectedCountry = countries.find(country => country.code === billingDetails.country) || null;
    const statesByCountry = getStatesByCountry(selectedCountry?.code || "");

    let selectedState = null;
    if (statesByCountry.length) {
        selectedState = statesByCountry.find(state => state.code === billingDetails.state) || null;
    }

    useEffect(() => {
        offerId && dispatch(getLocalBillingDetails());
    }, [dispatch, offerId]);

    const handleInputChange = (e: ChangeEvent): void => {
        updateBillingDetails({...billingDetails, [e.target.name]: e.target.value});
    };

    const handleAutocompleteChange = (name: string, value: CountryType | StateType | null) => {
        let lastBillingDetails = {...billingDetails, [name]: value?.code || ""}
        if (name === "country" && billingDetails.country !== value?.code) {
            lastBillingDetails = {...lastBillingDetails, state: ""}
        }
        updateBillingDetails(lastBillingDetails);
    }

    const handlePhoneChange = (value: string) => updateBillingDetails({...billingDetails, phone: value});

    const updateBillingDetails = (billingDetails: IBillingAddress) => {
        dispatch(updateLocalBillingDetails(billingDetails));
    }

    return (
        <>
            <Grid container columnSpacing={2} rowSpacing={{xs: 2, sm: 1}}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={selectedCountry}
                            onChange={(e: SyntheticEvent, value: CountryType | null) => handleAutocompleteChange("country", value)}
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) =>
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <Box
                                        component={"img"}
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt={option.code.toLowerCase()}
                                    />
                                    {option.label} ({option.code})
                                </Box>
                            }
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    name={"country"}
                                    label="Enter/Select Country"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    required
                                    error={!!getFieldError("country")}
                                    helperText={getFieldError("country")}
                                />
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={selectedState}
                            onChange={(e: SyntheticEvent, value: StateType | null) => handleAutocompleteChange("state", value)}
                            options={statesByCountry}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label} ({option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name={"state"}
                                    label="Enter/Select State"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    required={statesByCountry?.length > 0}
                                    error={!!getFieldError("state")}
                                    helperText={getFieldError("state")}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            name="city"
                            label={"Enter City"}
                            required
                            value={billingDetails.city}
                            onChange={handleInputChange}
                            error={!!getFieldError("city")}
                            helperText={getFieldError("city")}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            name="street"
                            label={"Enter Street Address"}
                            required
                            value={billingDetails.street}
                            onChange={handleInputChange}
                            error={!!getFieldError("street")}
                            helperText={getFieldError("street")}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            name="email"
                            label={"Enter Card Holder’s Email"}
                            required
                            value={billingDetails.email}
                            onChange={handleInputChange}
                            error={!!getFieldError("email")}
                            helperText={getFieldError("email")}
                            type={"email"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            name="zip"
                            label={"Enter ZIP/Postal Code"}
                            required
                            value={billingDetails.zip}
                            onChange={handleInputChange}
                            error={!!getFieldError("zip")}
                            helperText={getFieldError("zip")}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm>
                    <FormControl fullWidth>
                        <PhoneField
                            value={billingDetails.phone}
                            onChange={handlePhoneChange}
                            name={"phone"}
                            label={"Enter Billing Phone"}
                            required
                            error={getFieldError("phone") as string}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default BillingForm;