import React, {SyntheticEvent} from "react";

//ENUMS
export enum Direction {
    Departure = "departure",
    Retour = "retour"
}

export enum Method {
    GET = "get",
    POST = "post",
    PUT = "put"
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE"
}

export enum PassengerType {
    ADULT = "adult",
    CHILD = "child",
    INFANT = "infant"
}

export enum PassengerView {
    BookingPassenger = "BookingPassenger",
    PaymentPassenger = "PaymentPassenger"
}

//INTERFACES
export interface IExpert {
    email: string;
    name: string;
    phone: string;
    additionalPhone?: string;
    picture?: string;
}

export interface IFlight {
    airTime: string;
    airlineCode: string;
    airlineLogo: string;
    airlineName: string;
    airplaneType?: string;
    arrTerminal?: string;
    depTerminal?: string;
    endAirportName: string;
    endDate: string;
    endLocationCode: string;
    endLocationName: string;
    flightClass: string;
    flightName: string;
    startAirportName: string;
    startDate: string;
    startLocationCode: string;
    startLocationName: string;
    stopOver: string;
    warningStopOverLocation?: string;
}

export interface IRoute {
    airTime: string;
    direction: string;
    endLocationCode: string;
    endLocationName: string;
    startLocationCode: string;
    startLocationName: string;
    flights: Array<IFlight>;
}

export interface IOfferTotal {
    adults: number;
    adultsPrice: string;
    children: number;
    childrenPrice: string;
    newborns: number;
    newbornsPrice: string;
    price: string;
    priceDetails: {
        price: number;
        travelProtectionPrice: number;
        plantATreePrice: number;
        expertRemunerationPrice: number;
        currency: string;
    }
}

export interface IOffer {
    bookUrl: string;
    comments: string;
    label: string;
    routes: Array<IRoute>;
    total: IOfferTotal;
    passedTime: number;
}

export interface IFlightOffer {
    expert: IExpert;
    offer: IOffer;
}

export interface IPassenger {
    gender: Gender;
    firstName: string;
    lastName: string;
    middleName?: string;
    dateOfBirth: string;
    id: number;
    redressNumber?: string;
    seatPreference?: string;
    mealPreference?: string;
    specialAssistance?: string;
    programNumbers?: Array<IProgramNumber>
}

export interface IPassengerContact {
    email: string;
    phone: string;
}

export interface IProgramNumber {
    frequentFlyer: string;
    number: string;
}

export interface IPassengerType {
    type: PassengerType;
    title: string;
}

export interface IBillingAddress {
    country: string;
    state: string;
    city: string;
    street: string;
    zip: string;
    email: string;
    phone: string;
}

export interface ICardInformation {
    number: string;
    expMonth: number | null;
    expYear: number | null;
    cvv: string;
    name: string;
    phone: string;
}

export interface ISaveOffer {
    passengers: Array<IPassenger>;
    passengerContact: IPassengerContact;
    billingDetails: IBillingAddress;
    offerDetails: {
        useTravelProtection: boolean;
        travelProtectionAmount: number;
        plantATreeAmount: number;
        expertRemuneration: number;
        totalPrice: number;
    }
    paymentInformation: ICardInformation
}

export interface IReview {
    rate: number;
    name: string;
    email: string;
    fromLocation: string;
    toLocation: string;
    message: string;
}

export interface IExpertRemuneration {
    amount: number;
    confirmed: boolean;
}

export interface IError {
    name: string;
    message: string;
}

//STATES INTERFACES
export interface IStateFlightOffer {
    routes: Array<IRoute>;
    total: IOfferTotal;
    passedTime: number;
    offerId: string;
}

export interface IStatePassengers {
    passengers: Array<IPassenger>;
    errors: Array<Array<IError>>;
    contact: IPassengerContact;
}

export interface IStateExpert {
    expert: IExpert | undefined;
    remuneration: IExpertRemuneration
}

export interface IStatePlantATree {
    amount: number;
    confirmed: boolean;
}

export interface IStateModal {
    cardCVVModal: boolean;
    cardPhoneModal: boolean;
}

export interface IGlobalState {
    flightOffer: IStateFlightOffer;
    passengers: IStatePassengers;
    expert: IStateExpert;
}

export interface IStateRate {
    rate: number
}

export interface IStateTravelProtection {
    state: boolean
    sum: number
}

export interface IStateBillingAddress {
    billingDetails: IBillingAddress;
    errors: Array<IError>;
}

export interface IStateCardInformation {
    cardInformation: ICardInformation;
    errors: Array<IError>;
}

export interface IStatePaymentConfirmation {
    confirmed: boolean;
}

export interface IStatePurchaseSummary {
    isOpen: boolean
}

//OTHER TYPES
export type MenuLink = {
    text: string;
    url: string;
}

export type StateType = {
    code: string;
    label: string;
}

export type CountryType = {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}

export type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
export type ChangeCheckEvent = SyntheticEvent;

export type ClickEvent = React.MouseEvent<any>;
export type ClickEventHandler = React.MouseEventHandler<any>;

export type SubmitEvent = React.FormEvent<HTMLFormElement>;