import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStatePaymentConfirmation} from "../../types";
import {paymentConfirmationSliceDefaultValue} from "../../defaultValues";
import {saveOffer} from "./thunks/paymentConfirmation";
import {
    STORAGE_BILLING_DETAILS,
    STORAGE_EXPERT_REMUNERATION,
    STORAGE_PASSENGER_CONTACT,
    STORAGE_PASSENGERS,
    STORAGE_PLANTATREE_AMOUNT,
    STORAGE_RATE,
    STORAGE_TRAVEL_PROTECTION
} from "../../constants";

const paymentConfirmationAdapter = createEntityAdapter();

const initialState: IStatePaymentConfirmation = paymentConfirmationAdapter.getInitialState(paymentConfirmationSliceDefaultValue);

const paymentConfirmationSlice = createSlice({
    name: "paymentConfirmation",
    initialState,
    reducers: {
        updatePaymentConfirmation: (state: IStatePaymentConfirmation, action: PayloadAction<boolean>) => {
            state.confirmed = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveOffer.fulfilled, (state: IStatePaymentConfirmation, action: PayloadAction<string>) => {
                const offerId = action.payload;
                sessionStorage.removeItem(STORAGE_PASSENGERS(offerId));
                sessionStorage.removeItem(STORAGE_PASSENGER_CONTACT(offerId));
                sessionStorage.removeItem(STORAGE_RATE(offerId));
                sessionStorage.removeItem(STORAGE_EXPERT_REMUNERATION(offerId));
                sessionStorage.removeItem(STORAGE_PLANTATREE_AMOUNT(offerId));
                sessionStorage.removeItem(STORAGE_TRAVEL_PROTECTION(offerId));
                sessionStorage.removeItem(STORAGE_BILLING_DETAILS(offerId));
            })
    }
});

export const {updatePaymentConfirmation} = paymentConfirmationSlice.actions
export default paymentConfirmationSlice.reducer;