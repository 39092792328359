import {Box, FormControl, Grid, TextField, Typography} from "@mui/material";
import Block from "../Block";
import {colors} from "../../theme";
import AlertText from "../AlertText";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {ChangeEvent, IPassengerContact} from "../../types";
import {
    fetchLocalPassengerContact,
    updateLocalPassengerContact
} from "../../features/passengers/thunks/passengerContact";
import PhoneField from "../PhoneField";
import useErrors from "../../hooks/useErrors";

const PassengersContactPerson: React.FC = () => {
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const dispatch = useAppDispatch();
    const contactPerson: IPassengerContact = useAppSelector(state => state.passengers.contact);
    const passengersErrors = useAppSelector(state => state.passengers.errors);
    const errors = passengersErrors[-1];
    const {getFieldError} = useErrors(errors);

    useEffect(() => {
        offerId && dispatch(fetchLocalPassengerContact());
    }, [dispatch, offerId]);

    const updateContactData = (contactPerson: IPassengerContact): void => {
        offerId && dispatch(updateLocalPassengerContact(contactPerson));
    }

    return (
        <Block bottomRounded>
            <Box
                sx={{
                    paddingTop: 1,
                    paddingBottom: {xs: 1, sm: 5},
                }}
            >
                <AlertText
                    text="Please enter Emergency Contact, confirmation email and phone number fields are required!"
                    sx={{
                        backgroundColor: colors.liteGrayBackground,
                        paddingX: 3,
                        paddingY: 2,
                        marginBottom: 4
                    }}
                />
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            sx={{
                                fontSize: "2.1rem",
                                fontWeight: 700
                            }}
                        >
                            Contact Person
                        </Typography>
                        <Typography>
                            Please provide information about a contact person we should get in touch with in case of
                            schedule change
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sm={8}
                        columnSpacing={1}
                        rowSpacing={3}
                        sx={{
                            alignItems: "flex-end"
                        }}
                        id={"passengers-contact"}
                    >
                        <Grid item xs={12} sm>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    name="email"
                                    type={"email"}
                                    label={"Confirmation email"}
                                    required
                                    value={contactPerson.email}
                                    onChange={(e: ChangeEvent) => updateContactData({
                                        ...contactPerson,
                                        email: e.target.value
                                    })}
                                    error={!!getFieldError("email")}
                                    helperText={getFieldError("email")}
                                    FormHelperTextProps={{
                                        sx: {
                                            '&.Mui-error': {
                                                position: {xs: "unset", sm: "absolute"},
                                                bottom: -21
                                            }
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm>
                            <FormControl fullWidth>
                                <PhoneField
                                    value={contactPerson.phone}
                                    onChange={(value: string) => updateContactData({
                                        ...contactPerson,
                                        phone: value
                                    })}
                                    name={"phone"}
                                    label={"Phone number"}
                                    required
                                    error={getFieldError("phone") as string}
                                    FormHelperTextProps={{
                                        sx: {
                                            '&.Mui-error': {
                                                position: {xs: "unset", sm: "absolute"},
                                                bottom: -21
                                            }
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Block>
    )
}

export default PassengersContactPerson;