import {formatPrice} from "../../../util";
import {Grid, Typography} from "@mui/material";
import React from "react";
import useTotal from "../useTotal";

const PriceDetails: React.FC = () => {
    const {total} = useTotal();

    return (
        <>
            <PriceTail
                title="Round-Trip Flight"
                subTitle="To be processed by SLT"
                price={total.price}
            />
            {total.priceDetails.travelProtectionPrice > 0 && <PriceTail
                title="Travel Care Service"
                subTitle="Non-refundable charge by SLT"
                price={formatPrice(total.priceDetails.travelProtectionPrice, total.priceDetails.currency)}
            />}
            {total.priceDetails.plantATreePrice > 0 && <PriceTail
                title="Plant a Tree Service"
                subTitle="Non-refundable charge by SLT"
                price={formatPrice(total.priceDetails.plantATreePrice, total.priceDetails.currency)}
            />}
            {total.priceDetails.expertRemunerationPrice > 0 && <PriceTail
                title="Expert remuneration"
                subTitle="Non-refundable charge by SLT"
                price={formatPrice(total.priceDetails.expertRemunerationPrice, total.priceDetails.currency)}
            />}
        </>
    );
}

export default PriceDetails;

type PriceTailProps = {
    title: string,
    subTitle: string,
    price: string
}
const PriceTail: React.FC<PriceTailProps> = props => {
    const {title, subTitle, price} = props;

    return (
        <Grid container sx={{paddingY: 1}}>
            <Grid item xs={9} md={8} lg={9}>
                <Typography component="span" sx={{fontSize: "1.9rem", fontWeight: 700, display: "block"}}>
                    {title}
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontSize: "1.4rem",
                        display: "block",
                        lineHeight: 1
                    }}>
                    {subTitle}
                </Typography>
            </Grid>
            <Grid item xs={3} md={4} lg={3} sx={{textAlign: "end"}}>
                <Typography component="span" sx={{fontSize: "1.9rem", fontWeight: 700}}>
                    {price}
                </Typography>
            </Grid>
        </Grid>
    )
}