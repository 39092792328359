import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFlightOffer, IStateExpert} from "../../types";
import {expertSliceDefaultValue} from "../../defaultValues";
import {fetchFlightOffer} from "../flight-offer/thunks/flightOffer";
import {getLocalExpertRemuneration, updateLocalExpertRemuneration} from "./thunks/expertRemuneration";
import {INITIAL_AGENT_REMUNERATION} from "../../constants";

const expertAdapter = createEntityAdapter();

const initialState: IStateExpert = expertAdapter.getInitialState(expertSliceDefaultValue);

const expertSlice = createSlice({
    name: "expert",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlightOffer.fulfilled, (state: IStateExpert, action: PayloadAction<IFlightOffer>) => {
                state.expert = action.payload.expert;
            })
            .addCase(getLocalExpertRemuneration.fulfilled, (state: IStateExpert, action: PayloadAction<number | null>) => {
                state.remuneration.amount = action.payload == null ? INITIAL_AGENT_REMUNERATION : action.payload;
                state.remuneration.confirmed = action.payload != null;
            })
            .addCase(updateLocalExpertRemuneration.fulfilled, (state: IStateExpert, action: PayloadAction<number>) => {
                state.remuneration.amount = action.payload;
                state.remuneration.confirmed = true;
            })
    },
});

export default expertSlice.reducer;