import {IBillingAddress, IError} from "../../types";
import {isValidCountry, isValidCountryState, isValidEmail, isValidNonEmptySting} from "../../validation";
import {getStatesByCountry} from "../../util";

export const validateBillingDetails = (billingDetails: IBillingAddress) => {
    let errors: Array<IError> = [];

    if (!isValidNonEmptySting(billingDetails.country)) {
        errors.push({name: 'country', message: "Country is empty"});
    } else if (!isValidCountry(billingDetails.country)) {
        errors.push({name: 'country', message: "Wrong country value"});
    }

    const statesByCountry = getStatesByCountry(billingDetails.country);
    if (statesByCountry.length) {
        if (!isValidNonEmptySting(billingDetails.state)) {
            errors.push({name: 'state', message: "State is empty"});
        } else if (!isValidCountryState(billingDetails.state, billingDetails.country)) {
            errors.push({name: 'state', message: "Wrong state value"});
        }
    }

    if (!isValidNonEmptySting(billingDetails.city)) {
        errors.push({name: 'city', message: "City is empty"});
    }

    if (!isValidNonEmptySting(billingDetails.street)) {
        errors.push({name: 'street', message: "Street address is empty"});
    }

    if (!isValidNonEmptySting(billingDetails.email)) {
        errors.push({name: 'email', message: "Email is empty"});
    } else if (!isValidEmail(billingDetails.email)) {
        errors.push({name: 'email', message: "Wrong email format"});
    }

    if (!isValidNonEmptySting(billingDetails.zip)) {
        errors.push({name: 'zip', message: "Zip is empty"});
    }

    if (!isValidNonEmptySting(billingDetails.phone)) {
        errors.push({name: 'phone', message: "Phone is empty"});
    }

    return errors;
}