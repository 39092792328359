import Block from "../Block";
import {Box, Button, Grid, Slider, SliderThumb, TextField, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {colors} from "../../theme";
import {cdn, formatPrice} from "../../util";
import {ChangeEvent, IExpert, IExpertRemuneration} from "../../types";
import {PaymentGrayBox} from "../../Pages/Payment";
import {useAppDispatch, useAppSelector} from "../../store";
import {
    getLocalExpertRemuneration,
    updateLocalExpertRemuneration
} from "../../features/expert/thunks/expertRemuneration";

const sums: Array<number> = [25, 35, 50, 75, 100];

const inputToSlider = (inputValue: number): number => {
    inputValue = inputValue < 25 ? 25 : inputValue;
    let sliderValue = 0;
    if (inputValue > 25 && inputValue <= 35) {
        sliderValue = (inputValue - 25) / 10;
    } else if (inputValue > 35 && inputValue <= 50) {
        sliderValue = (inputValue - 35) / 15 + 1;
    } else if (inputValue > 50 && inputValue <= 75) {
        sliderValue = (inputValue - 50) / 25 + 2;
    } else if (inputValue > 75 && inputValue <= 100) {
        sliderValue = (inputValue - 75) / 25 + 3;
    } else if (inputValue > 100) {
        sliderValue = 4;
    }

    return sliderValue;
}

const AgentRemuneration: React.FC = () => {
    const dispatch = useAppDispatch();
    const expert: IExpert | undefined = useAppSelector(state => state.expert.expert);
    const expertRemuneration: IExpertRemuneration = useAppSelector(state => state.expert.remuneration);
    const priceCurrency: string = useAppSelector(state => state.flightOffer.total.priceDetails.currency);
    const offerId: string = useAppSelector(state => state.flightOffer.offerId);

    const [sliderValue, setSliderValue] = useState<number>(inputToSlider(expertRemuneration.amount));
    const [textValue, setTextValue] = useState<number>(expertRemuneration.amount);
    const shouldButtonBeDisabled = useCallback(
        () => expertRemuneration.amount === textValue && expertRemuneration.confirmed,
        [expertRemuneration, textValue, offerId]
    );
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(shouldButtonBeDisabled());

    const marks = sums.map((item, index) => {
        return {value: index, label: formatPrice(item, priceCurrency, 0)}
    });

    useEffect(() => {
        if (offerId) {
            dispatch(getLocalExpertRemuneration());
        }
    }, [dispatch, offerId]);

    useEffect(() => {
        setConfirmButtonDisabled(shouldButtonBeDisabled());
    }, [expertRemuneration, textValue, offerId]);

    useEffect(() => {
        setTextValue(expertRemuneration.amount);
        setSliderValue(inputToSlider(expertRemuneration.amount));
    }, [expertRemuneration.amount]);

    const handleChangeSlider = (e: Event, value: number | number[]) => {
        setSliderValue(value as number);
        setTextValue(sums[value as number])
    }

    const handleChangeInput = (e: ChangeEvent) => {
        let inputValue = parseInt(e.target.value || expertRemuneration.amount.toString());
        setSliderValue(inputToSlider(inputValue));
        setTextValue(inputValue);
    };

    const handleConfirm = () => {
        dispatch(updateLocalExpertRemuneration(textValue));
        setConfirmButtonDisabled(true);
    }

    return (
        <Block>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: 5,
                paddingX: {xs: 1, md: 3},
            }}>
                {expert && <Box
                    component="img"
                    src={expert.picture || cdn("/images/expert.png")}
                    alt={"avatar"}
                    sx={{borderRadius: "50%", width: 95}}
                />}
                <Box sx={{paddingLeft: 2, display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                    <Typography sx={{fontWeight: 700, fontSize: "2.8rem"}}>Did I met Your expectations?</Typography>
                    <Typography sx={{fontWeight: 500, fontSize: "1.8rem"}}>
                        If You feel that the service provided was exceptional, You can express your gratitude (optional)
                    </Typography>
                </Box>
            </Box>

            <Box sx={{paddingX: {xs: 3, md: 9}, paddingY: {xs: 5, md: 7}}}>
                <Slider
                    slots={{thumb: ThumbComponent}}
                    onChange={handleChangeSlider}
                    value={sliderValue}
                    marks={marks}
                    min={0}
                    max={4}
                    //scale={calculateScale}
                    step={1}
                    size={"medium"}
                    sx={{
                        color: colors.gray,
                        '& .MuiSlider-thumb': {
                            height: 5 * sliderValue + 24,
                            width: 5 * sliderValue + 24,
                            backgroundColor: colors.red,
                            '& .thumb-content': {
                                fontSize: 4 * sliderValue + 16
                            }
                        },
                        '& .MuiSlider-markLabel': {
                            fontSize: "2.8rem",
                            fontWeight: 500
                        }
                    }}
                />
            </Box>

            <Grid container sx={{paddingX: {xs: 1, md: 7}}} columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12} sm={8}>
                    <Typography sx={{fontSize: "2.8rem", fontWeight: 700}}>How was my service?</Typography>
                    <Typography
                        sx={{fontSize: "1.8rem", fontWeight: 500}}
                    >
                        If You feel that the service provided was exceptional, You can enter higher amount manually
                        (optional)
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography
                        sx={{
                            fontSize: "2.1rem",
                            fontWeight: 500
                        }}
                    >
                        Think I did better?
                    </Typography>
                    <TextField
                        value={textValue}
                        onChange={handleChangeInput}
                        sx={{maxWidth: 234}}
                        InputProps={{sx: {fontSize: "2.8rem", fontWeight: 500}}}
                        inputProps={{
                            min: 0,
                            type: 'number'
                        }}
                    />
                </Grid>
            </Grid>

            <PaymentGrayBox
                sx={{
                    borderRadius: "9px",
                    marginTop: 6,
                    paddingY: 4,
                    columnGap: 3,
                    paddingX: {xs: 1, md: 7},
                    rowGap: 3,
                    flexDirection: {xs: "column", sm: "row"}
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 700
                    }}
                >
                    By clicking button "Confirm" I agree, that this amount will be charged in addition to the cost of
                    the airline ticket(s).
                </Typography>
                <Button
                    onClick={handleConfirm}
                    disabled={confirmButtonDisabled}
                >
                    Confirm
                </Button>
            </PaymentGrayBox>
            <Box sx={{height: 8}}></Box>
        </Block>
    );
}

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {
}

const ThumbComponent: React.FC<ThumbComponentProps> = (props) => {
    const {children, ...other} = props;
    return (
        <SliderThumb {...other}>
            {children}
            <Typography className={"thumb-content"} sx={{fontWeight: 500, color: colors.white}}>$</Typography>
        </SliderThumb>
    );
}

export default AgentRemuneration;