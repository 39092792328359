import React from "react";
import {Box, Typography} from "@mui/material";
import Rate from "./index";

const PaymentRate: React.FC = () => {
    return (
        <Box sx={{
            paddingY: 2,
            textAlign: "center"
        }}>
            <Typography component="p" sx={{fontSize: "2.1rem", fontWeight: 700}}>Please rate</Typography>
            <Typography component="p" sx={{fontSize: "1.4rem"}}>the quality of Service</Typography>

            <Box
                sx={{
                    marginX: {xs: 2, sm: 8, md: 0, lg: 4},
                    marginTop: 3
                }}
            >
                <Rate/>
            </Box>
        </Box>
    );
}

export default PaymentRate;