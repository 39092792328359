import {formatPrice} from "../../../util";
import {Box, SxProps, Typography} from "@mui/material";
import {colors} from "../../../theme";
import React from "react";
import useTotal from "../useTotal";

type Props = {
    textSx?: SxProps
}
const Total: React.FC<Props> = (props) => {
    const {textSx = {}} = props;
    const {total, totalPrice} = useTotal();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingY: 2
            }}
        >
            <Typography
                component="span"
                sx={{
                    fontSize: "2.1rem",
                    fontWeight: 700,
                    ...textSx
                }}
            >
                TOTAL
            </Typography>

            <Typography
                component={"span"}
                sx={{
                    fontSize: "2.8rem",
                    fontWeight: 700,
                    color: colors.red
                }}
            >
                {formatPrice(totalPrice, total.priceDetails.currency)}
            </Typography>
        </Box>
    );
}

export default Total;