import React from "react";
import {colors} from "../../theme";
import {Container, Typography} from "@mui/material";
import ColoredBox from "../../components/ColoredBox";
import ReviewForm from "../../components/ReviewForm";

const Confirmation: React.FC = props => {
    return (
        <>
            <ColoredBox
                color={colors.secondDarkBackground}
            >
                <Container
                    maxWidth={"xl"}
                    sx={{
                        paddingY: {xs: 2, sm: 9, lg: 18},
                        textAlign: "center"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "6.2rem",
                            color: colors.red,
                            fontWeight: 600,
                            marginBottom: 4
                        }}
                    >
                        Thank you for your request!
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "4.2rem",
                            color: colors.white,
                            fontWeight: 600
                        }}
                    >
                        We will come back to you with a fresh information about beating your price
                    </Typography>
                </Container>
            </ColoredBox>
            <ColoredBox
                color={colors.grayBackground}
            >
                <Container
                    maxWidth={"sm"}
                    sx={{
                        paddingTop: {xs: 2, sm: 7, lg: 14},
                        paddingBottom: {xs: 2, sm: 23, lg: 24},
                        textAlign: "center"
                    }}
                >
                    <ReviewForm/>
                </Container>
            </ColoredBox>
        </>
    );
}

export default Confirmation;