import React from "react";
import RouteSummary from "./RouteSummary";
import {Box} from "@mui/material";
import Block from "../Block";
import PaymentRate from "../Rate/PaymentRate";
import DesktopPriceSummary from "./PriceSummary/DesktopPriceSummary";

const Desktop: React.FC = props => {
    return (
        <Box
            sx={{
                position: "sticky",
                top: 0,
                maxHeight: "100vh",
                overflowY: "auto"
            }}
        >
            <RouteSummary/>
            <Box sx={{height: "1px"}}></Box>
            <DesktopPriceSummary/>
            <Box sx={{height: 8}}></Box>
            <Block topRounded bottomRounded>
                <PaymentRate/>
            </Block>
        </Box>
    );
}

export default Desktop;