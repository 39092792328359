import React, {useEffect} from "react";

import {Box, SxProps} from "@mui/material";
import {colors} from "../../theme";
import Routes from "../../components/Routes";
import {useAppDispatch, useAppSelector} from "../../store";
import PurchaseSummary from "../../components/PurchaseSummary";
import TravelProtection from "../../components/TravelProtection";
import PlantATree from "../../components/PlantATree";
import PaymentInformation from "../../components/PaymentInformation";
import BillingDetails from "../../components/BillingDetails";
import AgentRemuneration from "../../components/AgentRemuneration";
import PaymentConfirmation from "../../components/PaymentConfirmation";
import PaymentPassengers from "../../components/Passengers/PaymentPassengers";
import {MAX_BOOKING_TIME} from "../../constants";
import PassengersContactPerson from "../../components/PassengersContactPerson";
import {fetchFlightOffer} from "../../features/flight-offer/thunks/flightOffer";
import Page2Columns from "../../components/Page/Page2Columns";

const headerStyle = {
    fontSize: "3.8rem",
    textAlign: "center",
    fontWeight: 600,
    paddingY: {xs: 2, md: 3}
};

const Payment: React.FC = props => {
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const dispatch = useAppDispatch();
    const routes = useAppSelector(state => state.flightOffer.routes);
    const passedTime: number = useAppSelector(state => state.flightOffer.passedTime);

    const bookingValid = passedTime <= MAX_BOOKING_TIME;

    useEffect(() => {
        if (bookingValid && offerId) {
            try {
                dispatch(fetchFlightOffer()).unwrap();
            } catch (e: any) {
                console.error(e)
                alert("Error on load passengers");
            }
        }
    }, [dispatch, offerId]);

    return (
        <>
            {(bookingValid && offerId) ? <>
                <Page2Columns
                    title={"Flight details"}
                    leftColumn={
                        <>
                            <Routes/>
                            <Box
                                sx={{
                                    marginTop: {xs: 4, lg: 5}
                                }}
                            >
                                {routes.length > 0 && <PaymentPassengers/>}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <PassengersContactPerson/>
                            </Box>

                            <Box sx={{
                                marginTop: 5
                            }}>
                                <TravelProtection/>
                            </Box>

                            <Box sx={{
                                marginTop: 5
                            }}>
                                <PlantATree/>
                            </Box>

                            <Box sx={{
                                marginTop: 5
                            }}>
                                <PaymentInformation/>
                            </Box>

                            <Box sx={{
                                marginTop: 5
                            }}>
                                <BillingDetails/>
                            </Box>

                            <Box sx={{
                                marginTop: 5
                            }}>
                                <AgentRemuneration/>
                            </Box>

                            <Box
                                id={"payment-confirmation"}
                                sx={{
                                    marginTop: 5
                                }}
                            >
                                <PaymentConfirmation/>
                            </Box>
                        </>
                    }
                    rightColumn={<PurchaseSummary/>}
                />
            </> : <Box sx={headerStyle}>Booking expired</Box>}
        </>
    )
}

type PaymentGrayBoxProps = {
    children: React.ReactNode | string,
    sx?: SxProps
}
export const PaymentGrayBox: React.FC<PaymentGrayBoxProps> = (props) => {
    const {sx = {}, children} = props;
    return (
        <Box
            sx={{
                backgroundColor: colors.liteGrayBackground,
                paddingY: 2,
                paddingX: {xs: 1, md: 3},
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...sx
            }}
        >
            {children}
        </Box>
    )
}

export default Payment;