import {configureStore} from "@reduxjs/toolkit";
import flightOfferReducer from "./features/flight-offer/flightOfferSlice";
import passengersReducer from "./features/passengers/passengersSlice";
import expertReducer from "./features/expert/expertSlice";
import modalReducer from "./features/modal/modalSlice";
import rateReducer from "./features/rate/rateSlice";
import plantATreeReducer from "./features/plantATree/plantATreeSlice";
import travelProtectionReducer from './features/travelProtection/travelProtectionSlice';
import billingDetailsReducer from './features/billingDetails/billingDetailsSlice';
import cardInformationReducer from './features/cardInformation/cardInformationSlice';
import paymentConfirmationReducer from "./features/paymentConfirmation/paymentConfirmationSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import purchaseSummaryReducer from "./features/purchaseSummary/purchaseSummarySlice";

export const store = configureStore({
    reducer: {
        flightOffer: flightOfferReducer,
        passengers: passengersReducer,
        expert: expertReducer,
        modal: modalReducer,
        rate: rateReducer,
        plantATree: plantATreeReducer,
        travelProtection: travelProtectionReducer,
        billingDetails: billingDetailsReducer,
        cardInformation: cardInformationReducer,
        paymentConfirmation: paymentConfirmationReducer,
        purchaseSummary: purchaseSummaryReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector