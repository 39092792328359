import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICardInformation, IError, IStateCardInformation} from "../../types";
import {cardInformationSliceDefaultValue} from "../../defaultValues";

const cardInformationAdapter = createEntityAdapter();

const initialState: IStateCardInformation = cardInformationAdapter.getInitialState(cardInformationSliceDefaultValue);

const cardInformationSlice = createSlice({
    name: "cardInformation",
    initialState,
    reducers: {
        addCardInformationErrors: (state: IStateCardInformation, action: PayloadAction<Array<IError>>) => {
            state.errors = action.payload;
        },
        updateCardInformation: (state: IStateCardInformation, action: PayloadAction<ICardInformation>) => {
            const {expYear, expMonth} = action.payload;
            state.cardInformation = action.payload;
            state.cardInformation.expYear = Number(expYear);
            state.cardInformation.expMonth = Number(expMonth);
        }
    }
});

export const {addCardInformationErrors, updateCardInformation} = cardInformationSlice.actions
export default cardInformationSlice.reducer;