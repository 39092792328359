import React, {ReactElement} from "react";
import {Typography} from "@mui/material";
import {colors} from "../../theme";

const RedText: React.FC<{ children: ReactElement | string }> = (props) => {
    return (
        <Typography
            component={"span"}
            sx={{
                color: colors.red,
                fontWeight: 500
            }}
        >
            {props.children}
        </Typography>
    )
}

export default RedText;