import Block from "../Block";
import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {cdn} from "../../util";
import {PaymentGrayBox} from "../../Pages/Payment";
import CardForm from "./CardForm";
import CardTypes from "./CardTypes";

const PaymentInformation: React.FC = () => {
    return (
        <Block title={"Payment information"} topRounded>
            <Box
                sx={{
                    paddingY: 1
                }}
            >
                <PaymentGrayBox
                    sx={{
                        marginTop: 1
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <Box
                            component={"img"}
                            src={cdn("/images/payment-lock.svg")}
                            alt={"lock"}
                        />
                        <Typography
                            sx={{
                                fontWeight: 500,
                                paddingX: 2
                            }}
                        >
                            All transactions are secure and encrypted.
                        </Typography>
                    </Box>
                    <Box>
                        <CardTypes/>
                    </Box>
                </PaymentGrayBox>
            </Box>

            <Box
                sx={{
                    paddingY: 3,
                    paddingX: {xs: 1, md: 3},
                }}
            >
                <Typography
                    sx={{
                        fontSize: "2.1rem",
                        fontWeight: 700,
                    }}
                >
                    Credit/Debit Card Information
                </Typography>
                <Grid container sx={{marginTop: 1}} columnSpacing={1}>
                    <Grid item xs={0} md={6}>
                        <Box
                            component={"img"}
                            src={cdn("/images/card.svg")}
                            alt={"credit card"}
                            sx={{maxWidth: "100%"}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} id="card-information">
                        <CardForm/>
                    </Grid>
                </Grid>
            </Box>
        </Block>
    );
}

export default PaymentInformation;