import React from "react";
import ModalWindow from "../Modal";
import {Box, Typography} from "@mui/material";
import {cdn} from "../../util";
import {useAppDispatch, useAppSelector} from "../../store";
import {openCardCVVModal} from "../../features/modal/modalSlice";
import RedText from "../RedText";

const CardCVVModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const open: boolean = useAppSelector(state => state.modal.cardCVVModal);

    const handleClose = () => {
        dispatch(openCardCVVModal(false))
    }

    return (
        <ModalWindow
            title={"Security Code CVV"}
            open={open}
            onClose={handleClose}
        >
            <Typography
                sx={{
                    paddingTop: 5,
                    fontWeight: 500
                }}
            >
                The Card Security Code is located on the back
                of <RedText>MasterCard</RedText>, <RedText>Visa</RedText> and <RedText>Discover</RedText> credit or
                debit card and is typically a separate group of 3 digits to the right of the signature strip.
            </Typography>
            <Box
                sx={{
                    textAlign: "center",
                    marginY: 3
                }}
            >
                <Box
                    component={"img"}
                    src={cdn("/images/card-cvv.svg")}
                    sx={{maxWidth: "100%"}}
                    alt={"credit card cvv"}
                />
            </Box>
            <Typography
                sx={{
                    paddingTop: 5,
                    fontWeight: 500
                }}
            >
                On <RedText>American Express</RedText> cards, the Card Security Code is a printed (NOT embossed)
                group of 4 digits on the front towards the right
            </Typography>
            <Box
                sx={{
                    textAlign: "center",
                    marginY: 3
                }}
            >
                <Box
                    component={"img"}
                    src={cdn("/images/card-cvv-amex.svg")}
                    sx={{maxWidth: "100%"}}
                    alt={"credit card cvv amex"}
                />
            </Box>
        </ModalWindow>
    )
}

export default React.memo(CardCVVModal);