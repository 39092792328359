import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBillingAddress, IError, IStateBillingAddress} from "../../types";
import {billingDetailsSliceDefaultValue} from "../../defaultValues";
import {getLocalBillingDetails, updateLocalBillingDetails} from "./thunks/billingDetails";

const billingDetailsAdapter = createEntityAdapter();

const initialState: IStateBillingAddress = billingDetailsAdapter.getInitialState(billingDetailsSliceDefaultValue);

const billingDetailsSlice = createSlice({
    name: "billingDetails",
    initialState,
    reducers: {
        addBillingDetailsErrors: (state: IStateBillingAddress, action: PayloadAction<Array<IError>>) => {
            state.errors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocalBillingDetails.fulfilled, (state: IStateBillingAddress, action: PayloadAction<IBillingAddress>) => {
                state.billingDetails = action.payload;
            })
            .addCase(updateLocalBillingDetails.fulfilled, (state: IStateBillingAddress, action: PayloadAction<IBillingAddress>) => {
                state.billingDetails = action.payload;
            })
    },
});

export const {addBillingDetailsErrors} = billingDetailsSlice.actions;
export default billingDetailsSlice.reducer;