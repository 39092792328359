import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../store";
import {cdn} from "../../util";
import {getLocalRate, updateLocalRate} from "../../features/rate/thunks/rate";

const redFilter = "invert(24%) sepia(100%) saturate(6696%) hue-rotate(345deg) brightness(96%) contrast(85%)";

const Rate: React.FC = () => {
    const dispatch = useAppDispatch();
    const rate: number = useAppSelector(state => state.rate.rate);
    const offerId = useAppSelector(state => state.flightOffer.offerId);

    useEffect(() => {
        if (offerId) {
            dispatch(getLocalRate());
        }
    }, [dispatch, offerId]);

    const addRate = (rate: number) => {
        rate > 0 && dispatch(updateLocalRate(rate));
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
        }}>
            {[1, 2, 3, 4, 5].map((rateValue, index) =>
                <Box
                    component={"img"}
                    key={index}
                    src={cdn('/images/rate'.concat(rateValue.toString()).concat('.svg'))}
                    onClick={() => addRate(rateValue)}
                    sx={{cursor: "pointer", filter: rateValue === rate ? redFilter : "unset"}}
                />
            )}
        </Box>
    );
}

export default Rate;