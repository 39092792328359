import {IStateFlightOffer} from "../../../types";
import {travelProtectionSliceDefaultValue} from "../../../defaultValues";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_TRAVEL_PROTECTION} from "../../../constants";

const getTravelProtectionFromStorage = async (offerId: string): Promise<boolean> => {
    let localTravelProtection: boolean = sessionStorage.getItem(STORAGE_TRAVEL_PROTECTION(offerId)) === "true";
    let travelProtection: boolean = travelProtectionSliceDefaultValue.state;

    if (!localTravelProtection) {
        setTravelProtectionInStorage(offerId, travelProtection);
    } else {
        travelProtection = localTravelProtection;
    }
    return travelProtection;
}

const setTravelProtectionInStorage = (offerId: string, travelProtection: boolean): void => {
    sessionStorage.setItem(STORAGE_TRAVEL_PROTECTION(offerId), travelProtection.toString());
}

export const getLocalTravelProtection = createAsyncThunk<boolean, void, {
    state: { flightOffer: IStateFlightOffer }
}>('travelProtection/getLocalTravelProtection', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        return await getTravelProtectionFromStorage(flightOffer.offerId);
    }

    return travelProtectionSliceDefaultValue.state;
});

export const updateLocalTravelProtection = createAsyncThunk<boolean, boolean, {
    state: { flightOffer: IStateFlightOffer }
}>('travelProtection/updateLocalTravelProtection', async (travelProtection: boolean, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        await getTravelProtectionFromStorage(flightOffer.offerId);
        setTravelProtectionInStorage(flightOffer.offerId, travelProtection);
    }

    return travelProtection;
});