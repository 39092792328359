import {CDN_URL, SITE_URL} from "./settings";
import {StateType} from "./types";
import {states} from "./constants";

export const cdn = (path: string): string => `${CDN_URL}${path}`;

export const siteUrl = (path?: string): string => `${SITE_URL}${path || ""}`;

export const getStatesByCountry = (countryCode: string): Array<StateType> => states.find(country => country.country === countryCode)?.values || [];

export const formatPrice = (price: number, currency: string, digits: number = 2) => Intl.NumberFormat("en", {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: digits
}).format(price);

export const loadExternalCss = (url: string) => {
    const linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", url);
    document.head.appendChild(linkElement);
}