import {ICardInformation, IError} from "../../types";
import {isValidCreditCard, isValidNonEmptySting, isValidNumber} from "../../validation";

export const validateCardInformation = (cardInformation: ICardInformation) => {
    let errors: Array<IError> = [];
    const currentYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth() + 1;

    if (!isValidNonEmptySting(cardInformation.number)) {
        errors.push({name: 'number', message: "Card number is empty"});
    } else if (!isValidCreditCard(cardInformation.number)) {
        errors.push({name: 'number', message: "Wrong card number"});
    }

    let isYearValid = true;
    if (!cardInformation.expYear) {
        errors.push({name: 'expYear', message: "Year is empty"});
        isYearValid = false;
    } else if (cardInformation.expYear < currentYear || cardInformation.expYear > currentYear + 4) {
        errors.push({name: 'expYear', message: "Wrong numeric Year value"});
        isYearValid = false;
    }

    if (!cardInformation.expMonth) {
        errors.push({name: 'expMonth', message: "Month is empty"});
    } else if (cardInformation.expMonth < 1 || cardInformation.expMonth > 12) {
        errors.push({name: 'expMonth', message: "Wrong numeric Month value"});
    } else if (isYearValid && currentYear === cardInformation.expYear && cardInformation.expMonth < currentMonth) {
        errors.push({name: 'expMonth', message: "Wrong Month value"});
    }

    if (!isValidNonEmptySting(cardInformation.cvv)) {
        errors.push({name: 'cvv', message: "CVV is empty"});
    } else if (!isValidNumber(cardInformation.cvv)) {
        errors.push({name: 'cvv', message: "Wrong CVV value"});
    } else if (cardInformation.cvv.length < 3 || cardInformation.cvv.length > 4) {
        errors.push({name: 'cvv', message: "Wrong CVV length value"});
    }

    if (!isValidNonEmptySting(cardInformation.name)) {
        errors.push({name: 'name', message: "Card Holder’s Name is empty"});
    }

    if (!isValidNonEmptySting(cardInformation.phone)) {
        errors.push({name: 'phone', message: "Phone is empty"});
    }

    return errors;
}