import React, {useEffect, useState} from "react";
import {Button, FormControl, TextField, Typography} from "@mui/material";
import {ChangeEvent, IError, IReview, IRoute, SubmitEvent} from "../../types";
import useErrors from "../../hooks/useErrors";
import {validateReviewForm} from "./validation";
import {useAppDispatch, useAppSelector} from "../../store";
import {saveReview} from "../../features/reviewForm/thunks/reviewForm";
import {fetchFlightOffer} from "../../features/flight-offer/thunks/flightOffer";
import ReviewRate from "../Rate/ReviewRate";

const ReviewForm: React.FC = props => {
    const dispatch = useAppDispatch();
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const routes: Array<IRoute> | undefined = useAppSelector(state => state.flightOffer.routes);
    const rate: number = useAppSelector(state => state.rate.rate);
    const formInitialData: IReview = {
        rate: 0,
        name: "",
        email: "",
        fromLocation: "",
        toLocation: "",
        message: ""
    };
    const [formData, setFormData] = useState<IReview>(formInitialData);
    const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError>>([]);
    const {getFieldError} = useErrors(errors);

    useEffect(() => {
        if (offerId) {
            try {
                dispatch(fetchFlightOffer()).unwrap();
            } catch (e: any) {
                console.error(e)
                alert("Error on load flights");
            }
        }
    }, [dispatch, offerId]);

    useEffect(() => {
        if (routes && routes[0]) {
            let from = "";
            let to = "";

            if (!formData.fromLocation) {
                from = routes[0].startLocationName
            }
            if (!formData.toLocation) {
                to = routes[0].endLocationName
            }

            setFormData(prevState => ({
                ...prevState,
                fromLocation: from,
                toLocation: to
            }))
        }
    }, [routes]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            rate: rate
        }))
    }, [rate]);

    const handlerSubmit = (e: SubmitEvent) => {
        e.preventDefault();
        const errors = validateReviewForm(formData);
        setErrors(errors);
        if (errors.length) {
            return;
        }
        submitForm();
    }

    const submitForm = async () => {
        try {
            await dispatch(saveReview(formData)).unwrap();
            setFormData(formInitialData);
            setFormIsSubmitted(true);
        } catch (e: any) {
            console.error(e);
        }
    }

    const handleInputChange = (e: ChangeEvent): void => setFormData(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value
    }));


    return (
        <>
            <Typography
                sx={{
                    fontSize: "2.8rem",
                    fontWeight: 500
                }}
            >
                {formIsSubmitted ? "Thank you for your review" : "Please leave your review"}
            </Typography>

            {!formIsSubmitted && <form
                onSubmit={handlerSubmit}
                noValidate={true}
            >
                <ReviewRate errorText={getFieldError("rate")}/>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 1
                    }}
                >
                    <TextField
                        variant="outlined"
                        name={"name"}
                        value={formData.name || ""}
                        label={"Name"}
                        onChange={handleInputChange}
                        error={!!getFieldError("name")}
                        helperText={getFieldError("name")}
                        required
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 1
                    }}
                >
                    <TextField
                        variant="outlined"
                        name={"email"}
                        value={formData.email || ""}
                        label={"Email"}
                        onChange={handleInputChange}
                        type={"email"}
                        error={!!getFieldError("email")}
                        helperText={getFieldError("email")}
                        required
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 1
                    }}
                >
                    <TextField
                        variant="outlined"
                        name={"fromLocation"}
                        value={formData.fromLocation || ""}
                        label={"From"}
                        onChange={handleInputChange}
                        error={!!getFieldError("fromLocation")}
                        helperText={getFieldError("fromLocation")}
                        required
                        sx={{
                            width: "50%"
                        }}
                    />
                    <TextField
                        variant="outlined"
                        name={"toLocation"}
                        value={formData.toLocation || ""}
                        label={"To"}
                        onChange={handleInputChange}
                        error={!!getFieldError("toLocation")}
                        helperText={getFieldError("toLocation")}
                        required
                        sx={{
                            width: "50%"
                        }}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                >
                    <TextField
                        variant="outlined"
                        name={"message"}
                        value={formData.message || ""}
                        label={"Message"}
                        onChange={handleInputChange}
                        error={!!getFieldError("message")}
                        helperText={getFieldError("message")}
                        required
                        multiline
                        rows={6}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <Button
                        variant={"contained"}
                        sx={{
                            borderTopRightRadius: "unset",
                            borderTopLeftRadius: "unset"
                        }}
                        type={"submit"}
                    >
                        Send
                    </Button>
                </FormControl>
            </form>}
        </>
    );
}

export default ReviewForm;