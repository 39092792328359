import {createAsyncThunk} from "@reduxjs/toolkit";
import {saveOffer as saveOfferToServer} from "../../../request";
import {
    STORAGE_BILLING_DETAILS,
    STORAGE_EXPERT_REMUNERATION,
    STORAGE_PASSENGER_CONTACT,
    STORAGE_PASSENGERS,
    STORAGE_PLANTATREE_AMOUNT,
    STORAGE_TRAVEL_PROTECTION
} from "../../../constants";
import {ISaveOffer, IStateCardInformation, IStateFlightOffer} from "../../../types";

export const saveOffer = createAsyncThunk<string, void, {
    state: { flightOffer: IStateFlightOffer, cardInformation: IStateCardInformation }
}>
('paymentConfirmation/saveOffer', async (_, {getState, rejectWithValue}) => {
    const {flightOffer, cardInformation} = getState();
    const offerId = flightOffer.offerId;

    const passengersJSON: string | null = sessionStorage.getItem(STORAGE_PASSENGERS(offerId));
    if (!passengersJSON) {
        return rejectWithValue("No passenger data found");
    }
    const passengersData = JSON.parse(passengersJSON);

    const passengerContactJSON: string | null = sessionStorage.getItem(STORAGE_PASSENGER_CONTACT(offerId));
    if (!passengerContactJSON) {
        return rejectWithValue("No passenger contact found");
    }
    const passengerContactData = JSON.parse(passengerContactJSON);

    const billingDetailsJSON: string | null = sessionStorage.getItem(STORAGE_BILLING_DETAILS(offerId));
    if (!billingDetailsJSON) {
        return rejectWithValue("No billing address found");
    }
    const billingDetailsData = JSON.parse(billingDetailsJSON);

    const travelProtectionData: boolean = sessionStorage.getItem(STORAGE_TRAVEL_PROTECTION(offerId)) === "true";

    const plantATreeAmountString: string | null = sessionStorage.getItem(STORAGE_PLANTATREE_AMOUNT(offerId));
    const plantATreeAmountData = parseInt(plantATreeAmountString || "0");

    const expertRemunerationString: string | null = sessionStorage.getItem(STORAGE_EXPERT_REMUNERATION(offerId));
    const expertRemunerationData = parseInt(expertRemunerationString || "0");

    const paymentInformationData = cardInformation.cardInformation;

    const total = flightOffer.total;
    const totalPrice = total.priceDetails.price + total.priceDetails.travelProtectionPrice + total.priceDetails.expertRemunerationPrice + total.priceDetails.plantATreePrice

    const offer: ISaveOffer = {
        passengers: passengersData,
        passengerContact: passengerContactData,
        billingDetails: billingDetailsData,
        offerDetails: {
            useTravelProtection: travelProtectionData,
            travelProtectionAmount: total.priceDetails.travelProtectionPrice,
            plantATreeAmount: plantATreeAmountData,
            expertRemuneration: expertRemunerationData,
            totalPrice: totalPrice
        },
        paymentInformation: paymentInformationData
    }

    await saveOfferToServer(offerId, offer);
    return offerId;
});