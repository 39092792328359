import {IError, IPassenger, IPassengerContact} from "../../types";
import {isValidDate, isValidEmail, isValidNonEmptySting, isValidPhone} from "../../validation";

export const validatePassengers = (passengersList: Array<IPassenger>): Array<Array<IError>> => {
    let errors: Array<Array<IError>> = [];
    passengersList.forEach((item) => {
        let passengerError = [];

        if (!isValidNonEmptySting(item.firstName)) {
            passengerError.push({name: 'firstName', message: "First Name is empty"});
        }
        if (!isValidNonEmptySting(item.lastName)) {
            passengerError.push({name: 'lastName', message: "Last Name is empty"});
        }
        if (!isValidNonEmptySting(item.dateOfBirth)) {
            passengerError.push({name: 'dateOfBirth', message: "Date of Birth is empty"});
        } else if (!isValidDate(item.dateOfBirth)) {
            passengerError.push({name: 'dateOfBirth', message: "Date of Birth is invalid"});
        }

        if (passengerError.length) {
            errors[item.id] = passengerError;
        }
    });

    return errors;
}

export const validatePassengersContact = (contact: IPassengerContact) => {
    let errors: Array<IError> = [];

    if (!isValidNonEmptySting(contact.email)) {
        errors.push({name: 'email', message: "Email is empty"});
    } else if (!isValidEmail(contact.email)) {
        errors.push({name: "email", message: "Wrong email format"})
    }

    if (!isValidNonEmptySting(contact.phone)) {
        errors.push({name: 'phone', message: "Phone is empty"});
    } else if (!isValidPhone(contact.phone)) {
        errors.push({name: 'phone', message: "Wrong phone format"});
    }

    return errors;
}