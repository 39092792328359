import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStateModal} from "../../types";
import {modalSliceDefaultValue} from "../../defaultValues";

const modalAdapter = createEntityAdapter();

const initialState: IStateModal = modalAdapter.getInitialState(modalSliceDefaultValue);

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openCardCVVModal: (state: IStateModal, action: PayloadAction<boolean>) => {
            state.cardCVVModal = action.payload;
        },
        openCardPhoneModal: (state: IStateModal, action: PayloadAction<boolean>) => {
            state.cardPhoneModal = action.payload;
        }
    }
});

export const {openCardCVVModal, openCardPhoneModal} = modalSlice.actions;
export default modalSlice.reducer;