import React from "react";

import {Box, SxProps, Typography} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {colors} from "../../theme";

type Props = {
    text: string,
    sx?: SxProps
};

const AlertText: React.FC<Props> = props => {
    const {text, sx = {}} = props;
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            ...sx
        }}>
            <InfoOutlinedIcon
                sx={{
                    color: colors.red,
                    alignSelf: "flex-start"
                }}
            />
            <Typography
                sx={{
                    fontSize: "1.4rem",
                    paddingLeft: 1,
                    fontWeight: 500
                }}
            >
                {text}
            </Typography>
        </Box>
    );
}

export default React.memo(AlertText);