import React from "react";
import ColoredBox from "../ColoredBox";
import {colors} from "../../theme";
import {Box, Container, Grid, Link, Theme, useMediaQuery} from "@mui/material";
import {cdn, siteUrl} from "../../util";

type MenuItem = {
    link?: string,
    text?: string,
    icon?: string,
    color?: string,
    hoverColor?: string,
}

const menus: Array<Array<MenuItem>> = [
    [
        {
            link: siteUrl('/page-faq'),
            text: 'FAQ',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: siteUrl('/about-us'),
            text: 'About Us',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: siteUrl('/#beat-my-price'),
            text: 'Beat my price',
            color: colors.red,
            hoverColor: colors.footerTextColor
        },
        {
            link: siteUrl('/impact'),
            text: 'Impact',
            color: colors.footerTextColor,
            hoverColor: colors.red
        }
    ],
    [
        {
            link: siteUrl('/contact'),
            text: 'Contacts',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: siteUrl('/reviews'),
            text: 'Wall of Fame and Shame',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: siteUrl('/page-terms-and-conditions'),
            text: 'Terms and Conditions',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: siteUrl('/page-privacy-policy'),
            text: 'Privacy Policy',
            color: colors.footerTextColor,
            hoverColor: colors.red
        }
    ],
    [
        {
            link: 'https://www.facebook.com/PriorityFlyers',
            text: 'Facebook',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: 'https://www.instagram.com/priority_flyers_',
            text: 'Instagram',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: '#',
            text: 'Youtube',
            color: colors.footerTextColor,
            hoverColor: colors.red
        },
        {
            link: '#',
            text: 'Linkedin',
            color: colors.footerTextColor,
            hoverColor: colors.red
        }
    ],
    [
        {
            //link: '',
            text: 'Visa',
            icon: 'https://www.priorityflyers.com/build/images/icons/visa.svg',
            //color: ''
        },
        {
            //link: '',
            text: 'Master',
            icon: 'https://www.priorityflyers.com/build/images/icons/master.svg',
            //color: ''
        },
        {
            //link: '',
            text: 'Discover',
            icon: 'https://www.priorityflyers.com/build/images/icons/discover.svg',
            //color: ''
        },
        {
            //link: '',
            text: 'Amex',
            icon: 'https://www.priorityflyers.com/build/images/icons/amex.svg',
            //color: ''
        }
    ],
];
const iconKey: number = 3;

const Footer: React.FC = () => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <>
            <ColoredBox color={colors.darkBackground}>
                <Container maxWidth={"xl"} sx={{paddingTop: {xs: "30px", md: "50px"}}}>
                    <Grid container>
                        {menus.map((menuGroup, groupKey) =>
                            <Grid item lg={3} md={groupKey === iconKey ? 12 : 4} key={groupKey} xs={12}>
                                <Box component={"ul"} sx={{
                                    borderLeft: {
                                        xs: `1px solid ${colors.gray}`,
                                        md: groupKey !== iconKey ? `1px solid ${colors.gray}` : 'none',
                                        lg: `1px solid ${colors.gray}`
                                    },
                                    margin: 0,
                                    paddingInlineStart: {
                                        xs: "55px",
                                        md: groupKey !== iconKey ? "55px" : 0,
                                        lg: "55px"
                                    },
                                    height: {
                                        xs: "100%",
                                        md: groupKey !== iconKey ? "100%" : "auto",
                                        lg: "100%"
                                    },
                                    marginTop: {
                                        xs: 0,
                                        md: groupKey !== iconKey ? 0 : "50px",
                                        lg: 0
                                    },
                                    textAlign: {
                                        xs: "start",
                                        md: groupKey !== iconKey ? "start" : "center",
                                        lg: "start"
                                    },
                                    'li:first-of-type a': {
                                        paddingTop: 0
                                    }
                                }}>
                                    {menuGroup.map((menuItem, itemKey) =>
                                        <Box component={"li"} sx={{
                                            display: {
                                                xs: groupKey !== iconKey ? "block" : "inline-block",
                                                lg: "block"
                                            },
                                            marginY: "3px",
                                            width: {
                                                xs: groupKey !== iconKey ? "auto" : 45,
                                                lg: "auto"
                                            }
                                        }} key={itemKey}>
                                            {!menuItem?.icon ?
                                                <Link
                                                    href={menuItem?.link}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    sx={{
                                                        color: menuItem?.color || "inherit",
                                                        display: "block",
                                                        fontWeight: 500,
                                                        lineHeight: "20px",
                                                        paddingY: "5px",
                                                        textDecoration: "none",
                                                        transition: "all .5s ease",
                                                        '&:hover': {
                                                            color: menuItem?.hoverColor || "inherit"
                                                        }
                                                    }}>
                                                    {menuItem.text}
                                                </Link> :
                                                <Box
                                                    component={"img"}
                                                    src={menuItem.icon}
                                                    alt={menuItem.text}
                                                    height={25}
                                                    width={40}/>
                                            }
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{
                        paddingY: 3,
                        color: colors.footerTextColor,
                        fontSize: "1.1rem",
                        lineHeight: "18px",
                        textAlign: "center"
                    }}>
                        *All the fares provided by Priority Flyers agents are provided are in USD and include all the
                        applicable taxes, fees and other surcharges. That means that all the prices are per person,
                        based on
                        economy class weekday travel (Monday - Thursday) from the USA, and depend on the chosen class of
                        service, departure city, airline and the routing. Kindly note that some airlines may impose
                        additional baggage charges. The fares are subject to seat availability in the corresponding
                        booking.
                        As the seats are limited they may not be available on all flights and dates. The fares are
                        non-refundable, non-exchangeable, and non-transferable. All fares are subject to change or
                        withdrawal without notice. Likewise, other restrictions may be applied. Consider that the less
                        restrictive fares are available at different rates. Please call Priority Flyers for the best
                        ongoing
                        prices and any guidance needed.
                    </Box>
                    <Box sx={{
                        width: {
                            sm: "66.6666%",
                            md: "33.3333%"
                        },
                        margin: "0 auto"
                    }}>
                        <Box
                            component={"img"}
                            src={cdn("/images/logo-blue.gif")}
                            sx={{width: "100%"}}
                            alt={"Logo"}>
                        </Box>
                    </Box>
                </Container>
            </ColoredBox>
            {isMobile && <Box sx={{height: 70}}></Box>}
        </>
    );
}

export default React.memo(Footer);