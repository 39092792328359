import React from "react";
import {Box} from "@mui/material";
import PriceDetails from "./PriceDetails";
import Total from "./Total";
import Block from "../../Block";
import SummaryOpenIcon from "./SummaryOpenIcon";
import {useAppDispatch, useAppSelector} from "../../../store";
import {openPurchaseSummary} from "../../../features/purchaseSummary/purchaseSummarySlice";

const MobilePriceSummary: React.FC = props => {
    const dispatch = useAppDispatch();
    const isSummaryOpen = useAppSelector(state => state.purchaseSummary.isOpen);

    const handleToggleOpen = () => dispatch(openPurchaseSummary(!isSummaryOpen));

    return (
        <>
            {
                isSummaryOpen && <Block>
                    <PriceDetails/>
                </Block>
            }
            {isSummaryOpen && <Box sx={{height: "1px", backgroundColor: "#E8EDF3"}}></Box>}
            <Block sx={{backgroundColor: "#1E2848"}}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Box
                        onClick={handleToggleOpen}
                        sx={{
                            width: "56px",
                            lineHeight: 1
                        }}
                    >
                        <SummaryOpenIcon open={isSummaryOpen}/>
                    </Box>
                    <Box sx={{width: "calc(100% - 56px)"}}>
                        <Total textSx={{color: '#FFFFFF'}}/>
                    </Box>
                </Box>
            </Block>
        </>
    );
}

export default MobilePriceSummary;