import {Box, FormControl, InputAdornment, TextField} from "@mui/material";
import React from "react";
import {cdn} from "../../util";
import {ChangeEvent, ClickEventHandler, ICardInformation} from "../../types";
import CardCVVModal from "./CardCVVModal";
import {useAppDispatch, useAppSelector} from "../../store";
import {openCardCVVModal, openCardPhoneModal} from "../../features/modal/modalSlice";
import CardPhoneModal from "./CardPhoneModal";
import {updateCardInformation} from "../../features/cardInformation/cardInformationSlice";
import useErrors from "../../hooks/useErrors";
import PhoneField from "../PhoneField";

const CardForm: React.FC = props => {
    const dispatch = useAppDispatch();
    const cardInformation: ICardInformation = useAppSelector(state => state.cardInformation.cardInformation);
    const errors = useAppSelector(state => state.cardInformation.errors);
    const {getFieldError} = useErrors(errors);

    const handleInputChange = (e: ChangeEvent): void => {
        updateDetails({...cardInformation, [e.target.name]: e.target.value});
    };

    const handlePhoneChange = (value: string) => updateDetails({...cardInformation, phone: value});

    const updateDetails = (cardInformation: ICardInformation) => {
        dispatch(updateCardInformation(cardInformation));
    }

    return (
        <>
            <FormControl fullWidth sx={{marginBottom: {xs: 2, sm: 1}}}>
                <TextField
                    variant="outlined"
                    name="number"
                    value={cardInformation.number}
                    onChange={handleInputChange}
                    error={!!getFieldError("number")}
                    helperText={getFieldError("number")}
                    label={"Enter Bank Card Number"}
                    required
                    inputProps={{inputMode: 'numeric', maxLength: 16}}
                />
            </FormControl>
            <Box sx={{
                display: "flex",
                columnGap: 1,
                marginBottom: {xs: 2, sm: 1},
                width: "100%"
            }}>
                <TextField
                    variant="outlined"
                    name="expMonth"
                    value={cardInformation.expMonth || ""}
                    onChange={handleInputChange}
                    error={!!getFieldError("expMonth")}
                    helperText={getFieldError("expMonth")}
                    sx={{width: "25%"}}
                    label={"MM"}
                    required
                    inputProps={{inputMode: 'numeric', maxLength: 2}}
                />
                <TextField
                    variant="outlined"
                    name="expYear"
                    value={cardInformation.expYear || ""}
                    onChange={handleInputChange}
                    error={!!getFieldError("expYear")}
                    helperText={getFieldError("expYear")}
                    sx={{width: "25%"}}
                    label={"YYYY"}
                    required
                    inputProps={{inputMode: 'numeric', maxLength: 4}}
                />
                <TextField
                    variant="outlined"
                    name="cvv"
                    value={cardInformation.cvv}
                    onChange={handleInputChange}
                    error={!!getFieldError("cvv")}
                    helperText={getFieldError("cvv")}
                    sx={{paddingLeft: 1, width: "50%"}}
                    label={"CVV / CVC"}
                    required
                    InputProps={{
                        endAdornment: <QuestionMarkAdornment onClick={() => dispatch(openCardCVVModal(true))}/>,
                    }}
                    inputProps={{inputMode: 'numeric', maxLength: 4}}
                    type={"password"}
                />
            </Box>
            <FormControl fullWidth sx={{marginBottom: {xs: 2, sm: 1}}}>
                <TextField
                    variant="outlined"
                    name="name"
                    value={cardInformation.name}
                    onChange={handleInputChange}
                    error={!!getFieldError("name")}
                    helperText={getFieldError("name")}
                    label={"Enter Card Holder’s Name"}
                    required
                />
            </FormControl>
            <FormControl fullWidth>
                <PhoneField
                    value={cardInformation.phone}
                    onChange={handlePhoneChange}
                    name={"phone"}
                    label={"Enter Phone Number"}
                    required
                    error={getFieldError("phone") as string}
                    InputProps={{
                        endAdornment: <QuestionMarkAdornment onClick={() => dispatch(openCardPhoneModal(true))}/>
                    }}
                />
            </FormControl>

            <CardCVVModal/>
            <CardPhoneModal/>
        </>
    );
}

type QuestionMarkAdornmentProps = {
    onClick: ClickEventHandler
}
const QuestionMarkAdornment: React.FC<QuestionMarkAdornmentProps> = (props) => {
    return (
        <InputAdornment position="end">
            <Box
                component={"img"}
                src={cdn("/images/question-mark-circle.svg")}
                sx={{cursor: "pointer"}}
                onClick={props.onClick}
            />
        </InputAdornment>
    );
}

export default CardForm;