import React from "react";
import Social from "../Social";
import {cdn} from "../../util";
import {colors} from "../../theme";
import {Box, Grid} from "@mui/material";
import RedText from "../RedText";
import Block from "../Block";
import {IRoute} from "../../types";
import {useAppSelector} from "../../store";

const RouteSummary: React.FC = () => {
    const routes: Array<IRoute> | undefined = useAppSelector(state => state.flightOffer.routes);
    const confirmationNumber: string | undefined = "";//should be taken from BO, usually it's set after sale is created

    return (
        <Block
            title={"Purchase summary"}
            subTitle={
                <Social
                    icon={cdn("/images/flight.svg")}
                    iconWidth={"27px"}
                    textSx={{
                        fontSize: "1.8rem",
                        fontWeight: 700,
                        color: colors.white,
                        paddingLeft: "12px"
                    }}
                    sx={{justifyContent: "center"}}
                >
                    Flight
                </Social>}
            topRounded
        >
            {routes?.map((route, key) =>
                <Grid container key={key} sx={{paddingTop: 2}}>
                    <Grid
                        item
                        xs={9}
                        md={8}
                        sx={{
                            fontSize: "1.6rem",
                            fontWeight: 700
                        }}
                    >
                        {route.startLocationName} ({route.startLocationCode})<br/>
                        to {route.endLocationName} ({route.endLocationCode})
                    </Grid>
                    <Grid item
                          xs={3}
                          md={4}
                          sx={{
                              textAlign: "end",
                              fontSize: "1.4rem"
                          }}
                    >
                        {route.flights[0].startDate}
                    </Grid>
                </Grid>
            )}
            {confirmationNumber && <Box sx={{paddingTop: 4, fontSize: "1.4rem", fontWeight: 700}}>
                Confirmation Nr.: <RedText>{confirmationNumber}</RedText>
            </Box>}
            <Box sx={{height: 16}}></Box>
        </Block>
    );
}

export default RouteSummary;