import {ClickEventHandler, IOfferTotal, IPassengerType, PassengerType} from "../../types";
import React from "react";
import {Box, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {colors} from "../../theme";
import ClearIcon from "@mui/icons-material/Clear";

export const getPassengerType = (totals: IOfferTotal, key: number): IPassengerType => {
    let passengerType: PassengerType = PassengerType.ADULT;
    let passengerTypeTitleText = "(Adult)";
    if (totals.adults >= key + 1) {
        //passengerType = PassengerType.ADULT;
        //passengerTypeTitleText = "(Adult)";
    } else if (totals.adults + totals.children >= key + 1) {
        passengerType = PassengerType.CHILD;
        passengerTypeTitleText = "(Child)";
    } else if (totals.adults + totals.children + totals.newborns >= key + 1) {
        passengerType = PassengerType.INFANT;
        passengerTypeTitleText = "(Infant)";
    }

    return {type: passengerType, title: passengerTypeTitleText};
}

type PassengerTitleProps = {
    title: string,
    showCloseIcon: boolean,
    onClickClose?: ClickEventHandler
}
export const PassengerTitle: React.FC<PassengerTitleProps> = props => {
    const {title, showCloseIcon, onClickClose} = props;
    return (
        <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} sx={{textAlign: "center"}}>
                <Box component="span" sx={{
                    fontSize: "1.8rem",
                    color: colors.white,
                    fontWeight: 700
                }}>
                    {title}
                </Box>
            </Grid>
            {showCloseIcon &&
                <Grid item xs={1}>
                    <Box sx={{textAlign: "end", cursor: "pointer"}}>
                        <ClearIcon sx={{color: colors.white}} onClick={onClickClose}/>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}

type NonEditFormControlProps = {
    label: string,
    value: string
}
export const NonEditFormControl: React.FC<NonEditFormControlProps> = props => {
    const {label, value} = props;
    return (
        <Box>
            <Typography>{label}</Typography>
            <Typography sx={{fontSize: "2.1rem", fontWeight: "bold"}}>{value}</Typography>
        </Box>
    )
}

type SelectFormControlProps = {
    label: string;
    options: Array<{ value: string, text: string }>;
    name: string;
    value: string;
    onChange: (e: SelectChangeEvent) => void;
}
export const SelectFormControl: React.FC<SelectFormControlProps> = props => {
    const {label, value, name, options, onChange} = props;
    return (
        <>
            <InputLabel
                id={`${name}-label`}
            >
                {label}
            </InputLabel>
            <Select
                sx={{fontWeight: 700, fontSize: "1.6rem"}}
                variant="outlined"
                name={name}
                labelId={`${name}-label`}
                value={value}
                onChange={onChange}
                //error={!!getFieldError("seatPreference")}
            >
                {
                    options.map((option, index) =>
                        <MenuItem
                            key={index}
                            value={option.value}
                        >
                            {option.text}
                        </MenuItem>
                    )
                }
            </Select>
        </>
    )
}