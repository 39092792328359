import moment from "moment/moment";
import validator from 'validator';
import {matchIsValidTel} from "mui-tel-input";
import {countries} from "./constants";
import {getStatesByCountry} from "./util";

export const isValidNonEmptySting = (value: string): boolean => !validator.isEmpty(value);

export const isValidEmail = (value: string): boolean => validator.isEmail(value);

export const isValidDate = (value: string): boolean => moment(value).isValid();

export const isValidCreditCard = (value: string): boolean => validator.isCreditCard(value);

export const isValidNumber = (value: string): boolean => /*/^\d+$/.test(value);*/validator.isInt(value);

export const isValidPhone = (value: string): boolean => matchIsValidTel(value);

export const isValidCountry = (value: string): boolean => countries.find(country => country.code === value)?.code?.length === 2;

export const isValidCountryState = (value: string, countryCode: string): boolean => {
    const country = countries.find(country => country.code === value)?.code || null;
    if (country) {
        const statesByCountry = getStatesByCountry(countryCode);
        if (!statesByCountry.length) {//country don't contain states, no check is necessary
            return true;
        }

        const state = statesByCountry.find(state => state.code === value)?.code || null;
        if (state) {
            return true;
        }
    }

    return false;
}