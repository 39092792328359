import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStateTravelProtection} from "../../types";
import {travelProtectionSliceDefaultValue} from "../../defaultValues";
import {getLocalTravelProtection, updateLocalTravelProtection} from "./thunks/travelProtection";

const travelProtectionAdapter = createEntityAdapter();

const initialState: IStateTravelProtection = travelProtectionAdapter.getInitialState(travelProtectionSliceDefaultValue);

const travelProtectionSlice = createSlice({
    name: "travelProtection",
    initialState,
    reducers: {
        setTravelProtectionSum: (state: IStateTravelProtection, action: PayloadAction<number>) => {
            state.sum = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocalTravelProtection.fulfilled, (state: IStateTravelProtection, action: PayloadAction<boolean>) => {
                state.state = action.payload;
            })
            .addCase(updateLocalTravelProtection.fulfilled, (state: IStateTravelProtection, action: PayloadAction<boolean>) => {
                state.state = action.payload;
            })
    },
});

export const {setTravelProtectionSum} = travelProtectionSlice.actions;
export default travelProtectionSlice.reducer;