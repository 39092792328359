import Block from "../Block";
import {Box} from "@mui/material";
import React from "react";
import {cdn} from "../../util";
import {PaymentGrayBox} from "../../Pages/Payment";
import BillingForm from "./BillingForm";
import AlertText from "../AlertText";

const antiviruses: Array<string> = [
    "mcafee", "thawte", "norton"
];

const BillingDetails: React.FC = () => {
    return (
        <Block title={"Billing details"} topRounded>
            <Box sx={{paddingY: 1}}>
                <PaymentGrayBox
                    sx={{
                        marginTop: 1
                    }}
                >
                    <AlertText
                        text={"Your billing address must match exactly the address on your credit card statement."}
                    />
                </PaymentGrayBox>
            </Box>

            <Box
                sx={{
                    paddingY: 3,
                    paddingX: {xs: 1, md: 3},
                }}
                id="billing-details"
            >
                <BillingForm/>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: {xs: "space-between", sm: "flex-end"},
                        flexWrap: "wrap",
                        columnGap: 4,
                        rowGap: 3,
                        marginTop: 3
                    }}
                >
                    {antiviruses.map(item =>
                        <Box
                            key={item}
                            component={"img"}
                            src={cdn(`/images/${item}.svg`)}
                            alt={item}
                        />
                    )}
                </Box>
            </Box>
        </Block>
    );
}

export default BillingDetails;