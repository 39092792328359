import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IError, IGlobalState, IPassenger, IPassengerContact, IStatePassengers} from "../../types";
import {passengersSliceDefaultValue} from "../../defaultValues";
import {
    addLocalPassenger,
    deleteLocalPassenger,
    fetchPaymentPassengers,
    updateLocalPassenger
} from "./thunks/passenger";
import {fetchLocalPassengerContact, updateLocalPassengerContact} from "./thunks/passengerContact";

const passengersAdapter = createEntityAdapter();

const initialState: IStatePassengers = passengersAdapter.getInitialState(passengersSliceDefaultValue);

const passengersSlice = createSlice({
    name: "passengers",
    initialState,
    reducers: {
        addPassengerErrors: (state: IStatePassengers, action: PayloadAction<Array<Array<IError>>>) => {
            state.errors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLocalPassenger.fulfilled, (state: IStatePassengers, action: PayloadAction<IPassenger>) => {
                state.passengers.push(action.payload);
            })
            .addCase(updateLocalPassenger.fulfilled, (state: IStatePassengers, action: PayloadAction<IPassenger>) => {
                const passenger = action.payload;
                const foundPassengerIndex = state.passengers.findIndex(item => item.id === passenger.id);

                state.passengers[foundPassengerIndex] = passenger;
            })
            .addCase(deleteLocalPassenger.fulfilled, (state: IStatePassengers, action: PayloadAction<IPassenger>) => {
                const passenger = action.payload;

                if (state.passengers.length > 1) {
                    state.passengers = state.passengers.filter(item => item.id !== passenger.id);
                }
            })
            .addCase(fetchPaymentPassengers.fulfilled, (state: IStatePassengers, action: PayloadAction<Array<IPassenger>>) => {
                state.passengers = action.payload;
            })
            .addCase(fetchLocalPassengerContact.fulfilled, (state: IStatePassengers, action: PayloadAction<IPassengerContact>) => {
                state.contact = action.payload;
            })
            .addCase(updateLocalPassengerContact.fulfilled, (state: IStatePassengers, action: PayloadAction<IPassengerContact>) => {
                state.contact = action.payload;
            })
    },
});

export const getAllPassengers = (state: IGlobalState): Array<IPassenger> => state.passengers.passengers;
export const getPassenger = (state: IGlobalState, id: number): IPassenger | undefined => state.passengers.passengers.find((item: IPassenger) => item.id === id);
export const {addPassengerErrors} = passengersSlice.actions;
export default passengersSlice.reducer;