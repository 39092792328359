import {IBillingAddress, IStateFlightOffer} from "../../../types";
import {billingDetailsSliceDefaultValue} from "../../../defaultValues";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {STORAGE_BILLING_DETAILS} from "../../../constants";

const getBillingDetailsFromStorage = async (offerId: string): Promise<IBillingAddress> => {
    let localBillingAddress: string | null = await sessionStorage.getItem(STORAGE_BILLING_DETAILS(offerId));
    let billingAddress: IBillingAddress = billingDetailsSliceDefaultValue.billingDetails;

    if (!localBillingAddress) {
        setBillingDetailsFromStorage(offerId, billingAddress);
    } else {
        billingAddress = JSON.parse(localBillingAddress);
    }
    return billingAddress;
}

const setBillingDetailsFromStorage = (offerId: string, billingDetails: IBillingAddress): void => {
    sessionStorage.setItem(STORAGE_BILLING_DETAILS(offerId), JSON.stringify(billingDetails));
}

export const getLocalBillingDetails = createAsyncThunk<IBillingAddress, void, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/getLocalBillingDetails', async (_, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        return await getBillingDetailsFromStorage(flightOffer.offerId);
    }

    return billingDetailsSliceDefaultValue.billingDetails;
});

export const updateLocalBillingDetails = createAsyncThunk<IBillingAddress, IBillingAddress, {
    state: { flightOffer: IStateFlightOffer }
}>('passengers/updateLocalBillingDetails', async (billingDetails: IBillingAddress, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        await getBillingDetailsFromStorage(flightOffer.offerId);
        setBillingDetailsFromStorage(flightOffer.offerId, billingDetails);
    }

    return billingDetails;
});