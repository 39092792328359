import {Box, Button, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import {colors} from "../../theme";
import ColoredBox from "../ColoredBox";
import {cdn} from "../../util";
import RedText from "../RedText";
import {
    ChangeCheckEvent,
    ClickEvent,
    IBillingAddress,
    ICardInformation,
    IPassengerContact,
    IStatePaymentConfirmation
} from "../../types";
import {validateCardInformation} from "../PaymentInformation/validation";
import {useAppDispatch, useAppSelector} from "../../store";
import {addCardInformationErrors} from "../../features/cardInformation/cardInformationSlice";
import {validateBillingDetails} from "../BillingDetails/validation";
import {addBillingDetailsErrors} from "../../features/billingDetails/billingDetailsSlice";
import {validatePassengersContact} from "../Passengers/validation";
import {addPassengerErrors} from "../../features/passengers/passengersSlice";
import {updatePaymentConfirmation} from "../../features/paymentConfirmation/paymentConfirmationSlice";
import {CheckboxIcon} from "../Icons";
import {saveOffer} from "../../features/paymentConfirmation/thunks/paymentConfirmation";
import {useNavigate} from "react-router-dom";

const PaymentConfirmation: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const offerId = useAppSelector(state => state.flightOffer.offerId);
    const paymentConfirmation: IStatePaymentConfirmation = useAppSelector(state => state.paymentConfirmation);
    const paymentConfirmationContainerId = document.getElementById("payment-confirmation");
    const cardInformation: ICardInformation = useAppSelector(state => state.cardInformation.cardInformation);
    const cardInformationContainerId = document.getElementById("card-information");
    const billingDetails: IBillingAddress = useAppSelector(state => state.billingDetails.billingDetails);
    const billingDetailsContainerId = document.getElementById("billing-details");
    const passengersContact: IPassengerContact = useAppSelector(state => state.passengers.contact);
    const passengersContactContainerId = document.getElementById("passengers-contact");

    const [showError, setShowError] = useState(false)

    const handleClickPayButton = async (e: ClickEvent) => {
        let alreadyScrolled = false;
        let errorsDetected = false;
        const passengersContactErrors = validatePassengersContact(passengersContact);
        let errors = [];
        errors[-1] = passengersContactErrors
        dispatch(addPassengerErrors(errors));
        if (passengersContactErrors.length) {
            errorsDetected = true;
            if (!alreadyScrolled) {
                passengersContactContainerId?.scrollIntoView({behavior: "smooth", block: "start"});
                alreadyScrolled = true;
            }
        }

        const cardInformationErrors = validateCardInformation(cardInformation);
        dispatch(addCardInformationErrors(cardInformationErrors));
        if (cardInformationErrors.length) {
            errorsDetected = true;
            if (!alreadyScrolled) {
                cardInformationContainerId?.scrollIntoView({behavior: "smooth", block: "start"});
                alreadyScrolled = true;
            }
        }

        const billingDetailsErrors = validateBillingDetails(billingDetails);
        dispatch(addBillingDetailsErrors(billingDetailsErrors));
        if (billingDetailsErrors.length) {
            errorsDetected = true;
            if (!alreadyScrolled) {
                billingDetailsContainerId?.scrollIntoView({behavior: "smooth", block: "start"});
                alreadyScrolled = true;
            }
        }

        if (!paymentConfirmation.confirmed) {
            errorsDetected = true;
            setShowError(true);
        }
        if (showError && !alreadyScrolled) {
            paymentConfirmationContainerId?.scrollIntoView({behavior: "smooth", block: "start"});
            alreadyScrolled = true;
        }

        if (!errorsDetected) {
            await submitOffer();
        }
    }

    const submitOffer = async () => {
        try {
            await dispatch(saveOffer()).unwrap();
            navigate(`/confirmation/${offerId}`);
        } catch (e: any) {
            console.error(e)
            alert("Error on save Offer from func catch");
        }
    }

    return (
        <ColoredBox
            color={colors.secondDarkBackground}
            topRounded
            bottomRounded
        >
            <Box
                sx={{
                    paddingX: {xs: 2, sm: 5},
                    paddingY: 4,
                    textAlign: "center"
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        sx={{
                            alignItems: "flex-start"
                        }}
                        control={
                            <Checkbox
                                icon={<CheckboxIcon htmlColor={showError ? colors.red : colors.gray}/>}
                            />
                        }
                        label={
                            <Typography
                                component={"span"}
                                sx={{
                                    fontWeight: 700,
                                    color: colors.white,
                                    paddingTop: "5px",
                                    textAlign: "start"
                                }}
                            >
                                By checking this box I affirm that I have read, understood and accepted
                                present <RedText>Terms and Conditions</RedText>, <RedText>Privacy
                                Policy</RedText> and <RedText>airline liability limitations</RedText>, confirmed the
                                accuracy of flight itinerary, and verified the spelling of the name(s) of the passenger
                                (s) to be matching passenger(s) passport(s) / <RedText>valid travel
                                document(s)</RedText>, as well as I have taken the consent of Credit Card Holder and all
                                the passengers regarding the <RedText>Terms and Conditions</RedText> and <RedText>Privacy
                                Policy</RedText>.
                            </Typography>
                        }
                        onChange={(e: ChangeCheckEvent, checked: boolean) => dispatch(updatePaymentConfirmation(checked))}
                        checked={paymentConfirmation.confirmed}
                    />
                </FormGroup>
                <Button
                    sx={{
                        marginY: 4
                    }}
                    onClick={handleClickPayButton}
                >
                    <Box
                        component={"img"}
                        src={cdn("/images/lock.svg")}
                        sx={{
                            marginRight: 1
                        }}
                    />
                    Pay securely now
                </Button>
            </Box>
        </ColoredBox>
    );
}

export default PaymentConfirmation;