import {createAsyncThunk} from "@reduxjs/toolkit";
import {IReview, IStateFlightOffer} from "../../../types";
import {saveReview as saveReviewToServer} from "../../../request";

export const saveReview = createAsyncThunk<void, IReview, {
    state: { flightOffer: IStateFlightOffer }
}>('reviewForm/sendReview', async (review: IReview, {getState}) => {
    const {flightOffer} = getState();

    if (flightOffer.offerId) {
        await saveReviewToServer(flightOffer.offerId, review);
    }
});