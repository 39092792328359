import React from "react";
import {Box} from "@mui/material";
import PriceDetails from "./PriceDetails";
import Total from "./Total";
import Block from "../../Block";

const DesktopPriceSummary: React.FC = props => {
    return (
        <>
            <Block>
                <PriceDetails/>
            </Block>
            <Box sx={{height: "1px", backgroundColor: "#E8EDF3"}}></Box>
            <Block bottomRounded>
                <Total/>
            </Block>
        </>
    );
}

export default DesktopPriceSummary;